import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import uuid from 'uuid';

import ModalDialog from './ModalDialog';
import AjaxComponent, { API } from './AjaxComponent';
import { UserContext } from './UserContext';

const styles = () => ({});

class MacListDialogs extends React.Component {
  static contextType = UserContext;

  state = { /* eslint-disable react/destructuring-assignment */
    selectedDevices: [this.props.device],
    allowedDevices: this.getAllowedDevices([this.props.device]),
    allowedHolders: this.props.holders,
    createdHolder: null,
    createdUser: null,
    selectedHolder: null,
    selectedAssembly: null,
    newAssemblyName: '',
  }; /* eslint-enable react/destructuring-assignment */

  getAllowedDevices(newSelectedDevices) {
    const { devices } = this.props;
    return devices.filter(i => (!newSelectedDevices.map(j => j.id).includes(i.id)));
  }

  render() {
    const { handleClose, device, devices } = this.props;
    const { selectedDevices, allowedDevices } = this.state;
    const user = this.context;
    const dialogsInfo = {
      first: 'tie',
      tie: {
        name: 'tie',
        title: 'Привязка к клиенту',
        actionsVertical: true,
        buttonContained: {
          title: 'Новый',
          onClick: () => 'new_client',
        },
        buttonOutlined: {
          title: 'Существующий',
          onClick: () => 'existing_client',
        },
        fields: [],
      },
      new_client: {
        name: 'new-client',
        title: 'Новый клиент',
        actionsVertical: false,
        buttonContained: {
          title: 'Далее',
          onClick: async (dialog) => {
            const vals = dialog.props.selectValues;
            try {
              const chkuser = await API.req(API.user(vals.email));
              if (chkuser.status === 404) {
                // it's ok, can create
              } else if (chkuser.status === 403) {
                // exists, not accessible
                const message = 'Пользователь не может быть создан - обратитесь в службу поддержки';
                this.setState({ errorMessage: message });
                return 'error';
              } else if (chkuser.email === vals.email) {
                const message = 'Пользователь с указанным email уже существует';
                this.setState({ errorMessage: message });
                return 'error';
              } else {
                console.log('Unknown result on user query', chkuser)
                this.setState({ errorMessage: 'Unknown object got' });
                return 'error';
              }
            } catch (e_unknown) {
              // perhaps not possible - catched in req()
              console.log('Unexpected exception on user query', e_unknown);
            }
            const hjson = {
              name: vals.name,
              parent_id: user.holder_id,
              status: vals.clientType === 'Дилер' ? 1 : 0,
            };
            try {
              const createdHolder = await API.req(API.holder(), 'POST', hjson);
              this.setState({ createdHolder, selectedHolder: createdHolder });
              const ujson = {
                email: vals.email,
                username: vals.email,
                holder_id: createdHolder.id,
                password: uuid.v4(),
              };
              try {
                const newuser = await API.req(API.user(), 'POST', ujson);
                return UserContext.isDealer(newuser) ? 'assign_dealer' : 'assembly_select';
              } catch (e2) {
                const message = 'Cannot create user';
                this.setState({ errorMessage: message + e2 });
              }
            } catch (e) {
              const message = 'Cannot create holder';
              this.setState({ errorMessage: message + e });
            }
            return 'error';
          },
        },
        buttonOutlined: false,
        fields: [
          {
            name: 'name',
            label: 'Имя',
            type: 'text',
          },
          {
            name: 'email',
            label: 'Email',
            type: 'text',
          },
          {
            name: 'clientType',
            label: 'Тип клиента',
            type: 'select',
            labelWidth: 76,
            options: ['Пользователь', 'Дилер'],
          },
        ],
      },
      existing_client: {
        name: 'existing-client',
        title: 'Существующий клиент',
        actionsVertical: false,
        buttonContained: {
          title: 'Далее',
          onClick: (dialog) => {
            if (API.isHolderDealer(this.state.selectedHolder)) {
              return 'assign_dealer';
            } else {
              return 'assembly_select';
            }
          },
        },
        buttonOutlined: false,
        render: dialog => { 
          console.log(this.state);
        return (
          <>
            <DialogTitle>
              Клиент:
            </DialogTitle>
            <FormControl fullWidth>
              <Select
                maxMenuHeight="120"
                onChange={(value) => {
                  this.setState({ selectedHolder: value.value });
                }}
                options={this.state.allowedHolders.map(h => ({label: h.name, value: h}))}
              />
            </FormControl>
          </>
        )
        },
      },
      assembly_select: {
        buttonContained: {
          title: 'Новое',
          onClick: async () => {
            const { newAssemblyName, selectedHolder } = this.state;
            const json = { name: newAssemblyName, holder_id: selectedHolder.id, device_ids: [] };
            const resp = await API.request(API.assembly(), 'POST', json);
            const selectedAssembly = await resp.json();
            console.log(selectedAssembly);
            this.setState({ selectedAssembly });
            return 'assign_dealer';
          },
        },
        buttonOutlined: {
          title: 'Существующее',
          onClick: () => 'assign_dealer',
        },
        render: dialog => (
          <>
            <DialogTitle>
              Выберите изделие или создайте новое
            </DialogTitle>
            <p>Введите имя нового изделия или выберите существующее из списка</p>
            <FormControl fullWidth>
              <Select
                onChange={(selectedValue) => {
                  this.setState({
                    selectedAssembly: selectedValue.value,
                  });
                }}
                options={this.props.assemblies
                  .filter(i => i.holder_id === this.state.selectedHolder.id)
                  .map(i => ({ label: `${i.id} (${i.name})`, value: i }))}
                placeholder="Выберите существующее"
              />
              <TextField
                placeholder="Имя нового изделия"
                value={this.state.newAssemblyName}
                onChange={event => this.setState({ newAssemblyName: event.target.value })}
              />
            </FormControl>
          </>
        ),
      },
      assign_dealer: {
        name: 'assign-dealer',
        actionsVertical: false,
        buttonContained: {
          title: 'Привязать',
          onClick: async () => {
            const { selectedHolder, selectedAssembly } = this.state;
            const promises = selectedDevices.map(item => API.request(
              API.device(item.id), 'PUT', { holder_id: selectedHolder.id },
            ));
            try {
              await Promise.all(promises);
              if (selectedAssembly) {
                const json = { device_ids: selectedDevices.map(i => i.id) };
                const res = await API.req(API.assembly(selectedAssembly.id), 'PUT', json);
                console.log(res);
              }
              this.props.refresh();
              return false;
            } catch (e) {
              this.setState({ errorMessage: e })
              return 'error';
            }
          },
        },
        buttonOutlined: {
          title: 'Назад',
          onClick: () => 'new_client',
        },
        render: () => (
          <>
            <DialogTitle>{`Клиент: ${this.state.selectedHolder.name}`}</DialogTitle>
            { this.state.selectedAssembly && (
              <p><strong>Изделие: {this.state.selectedAssembly.name}</strong></p>
            ) }
            <p><strong>Привязать клапаны:</strong></p>
            { selectedDevices.map(item => (
              <p key={item.id} className="modal-custom__value">
                MAC адрес:
                {' '}
                {item.id}
                <Button
                  onClick={() => {
                    const newSelectedDevices = selectedDevices.filter(d => d.id !== item.id);
                    // refactor
                    const newAllowedDevices = this.getAllowedDevices(newSelectedDevices);
                    this.setState({
                      selectedDevices: newSelectedDevices,
                      allowedDevices: newAllowedDevices,
                    });
                  }}
                >
                  Удалить
                </Button>
              </p>
            )) }
            <FormControl fullWidth>
              <Select
                onChange={(selectedValue) => {
                  const newSelectedDevices = [...selectedDevices, selectedValue.value];
                  const newAllowedDevices = this.getAllowedDevices(newSelectedDevices);
                  this.setState({
                    selectedDevices: newSelectedDevices,
                    allowedDevices: newAllowedDevices,
                  });
                }}
                options={allowedDevices.map(i => ({ label: `${i.id} (${i.name})`, value: i }))}
              />
            </FormControl>
          </>
        ),
      },
      existing_client_2: {
        name: 'existing-client-2',
        title: 'Клиент: Иван Иванов',
        subheader: 'Список изделий',
        actionsVertical: false,
        buttonContained: {
          title: 'Далее',
          onClick: dialog => 'existing_client_3',
        },
        buttonOutlined: {
          title: 'Назад',
          onClick: () => 'existing_client',
        },
        fields: [
          {
            name: 'assembly',
            label: 'Изделие',
            type: 'select',
            labelWidth: 53,
            feedUrl: AjaxComponent.apiAssemblyGet(),
            // options: ['Изделие 1', 'Изделие 2', 'Изделие 3'],
          },
          {
            name: 'newAssembly',
            label: 'Добавить новое изделие',
            type: 'add',
          },
        ],
      },
      existing_client_3: {
        name: 'existing-client-3',
        title: 'Клиент: Иван Иванов',
        actionsVertical: false,
        buttonContained: {
          title: 'Привязать',
          onClick: (dialog) => {
            const { client, assembly } = dialog.props.selectValues;
            console.log(client, assembly, device);
            return false;
          },
        },
        buttonOutlined: {
          title: 'Назад',
          onClick: _ => 'existing_client_2',
        },
        fields: [
          {
            name: 'assembly',
            label: 'Изделие',
            type: 'defined',
            value: this.props.assembly,
            edit: true,
          },
          {
            name: 'addedValve',
            label: 'Добавленный клапан',
            type: 'defined',
            value: device.name,
            edit: true,
          },
          {
            name: 'newValve',
            label: 'Добавить новый клапан',
            type: 'add',
          },
        ],
      },
      error: {
        render: dialog => (
          <p> { this.state.errorCode } { this.state.errorMessage } </p>
        ),
        buttonContained: {
          title: 'Заново',
          onClick: () => 'tie',
        },
        buttonOutlined: {
          title: 'Закрыть',
          onClick: () => false,
        },
      },
    };
    const selectValues = {};

    return (
      <div>
        <ModalDialog
          dialogsInfo={dialogsInfo}
          handleClose={handleClose}
          selectValues={selectValues}
        />
      </div>
    );
  }
}

MacListDialogs.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  device: PropTypes.shape({}).isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  holders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assemblies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withStyles(styles)(MacListDialogs);
// vim: ts=2 sw=2 et :
