import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Chart from 'react-apexcharts';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import AjaxComponent from './AjaxComponent';

import AjaxButton from './AjaxButton';
import RegenChart, { cycleTr } from './RegenChart';

const styles = () => ({});

const transHeaders = {
  'total-remain': 'Время до окончания промывки',
  'total-time': 'Полное время промывки',
  'current-cycle': 'Текущая стадия промывки',
  'cycle-time': 'Время стадии',
};

const cycleCodes = {
  1: 'Fill',
  3: 'Regenerant Draw Up',
  5: 'Regenerant Draw Down',
  7: 'Backwash',
  9: 'Rinse',
  13: 'Softening/Service',
};

const options = {
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      dataLabels: { name: { show: false }, value: { offsetY: -15 } },
    },
  },
};

function sectohms(sec) {
  // console.log(sec);
  const res = new Date(null);
  res.setSeconds(sec);
  return sec ? res.toISOString().substr(11, 8) : '-';
}

/**
 * Блок информации о промывке клапана (вкладка "Промывка").
 *
 * Обображается, если клапан находится в режиме промывки.
 * Стадии промывки извлекаются при инициализации компонента из API
 * по команде клапана 206.
 */
class DeviceRegen extends AjaxComponent {
  /**
   * Данные для запроса к RESTful API
   * @prop {string} c110 URL запроса по команде 110.
   * */
  urls = {
    c110: AjaxComponent.apiMessageGet(this.props.id, 110, 2),
  }

  /** Состояние React-компонента. */
  state = { cycles: [], status: {} }

  /**
   * Функция инициализации компонента
   * (вызывается при появлении на странице)
   *
   * Запускает таймеры опросов данных и проводит первичные опросы API.
   */
  componentDidMount() {
    super.componentDidMount();
    this.timer = setInterval(() => {
      this.fetchStatus();
      this.fetch();
    }, 5000);
    this.fetchConfig();
    this.fetchStatus();
  }

  /**
   * Функция деинициализации компонента
   * (вызывается при удалении со страницы).
   *
   * Останавливает таймеры опросов данных.
   */
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * Загрузка конфигурации промывки клапана из команд 201 и 206.
   */
  fetchConfig() {
    this.fetchOne('cycles', AjaxComponent.apiMessageGet2(this.props.id, 206, '', '', '', 1, 1));
    this.fetchOne('config', AjaxComponent.apiMessageGet2(this.props.id, 201, '', '', '', 1, 1));
  }

  /**
   * Загрузка статуса промывки клапана из команды 110.
   */
  fetchStatus() {
    this.fetchOne('status', AjaxComponent.apiMessageGet2(this.props.id, 110, '', '', '', 1, 2));
  }

  /**
   * Обработчик поступивших данных из API.
   * @param {string} id Ключ массива this.urls.
   * @param {object} Загруженные данные в виде JSON.
   */
  dataFetched(id, data) {
    if (id === 'cycles') {
      this.setState({ cycles: data[0].data['Regen-Settings']['Regen Cycles'].filter(item => !['End', 'Open'].includes(item.Cycle)) });
    } else if (id === 'status') {
      this.setState({ status: { lag: data[0].lag, ...data[0].data } });
    } else if (id === 'config') {
      this.setState({ config: data[0].data['Installer-Settings'] });
    } else {
      super.dataFetched(id, data);
    }
  }

  /** Основная функция отрисовки компонента. */
  render() {
    const N = 60;
    const rowconfig = [
      ['c110', 'total-remain', sectohms],
      ['c110', 'current-cycle', src => cycleTr[cycleCodes[src]]],
      ['c110', 'cycle-time', sectohms],
    ];
    const rowconfig1 = [
      ['Время начала промывки', data => ['Regen Time-Hour', 'Regen Time-Minute']
        .map(item => data[item].toString().padStart(2, '0')).join(':')],
      ['Число дней между промывками', data => `${data['Day Override']}`],
    ];
    const {
      c110, config, cycles, status
    } = this.state;
    const active = status.lag < N;
    // console.log('is on', active, status, config);
    // console.log(c110);
    const rows1 = rowconfig1.map(item => ({
      key: item[0],
      header: item[0],
      value: config ? item[1](config) : '-',
    }));
    const rows = rowconfig.map(item => ({
      key: item[1],
      header: transHeaders[item[1]],
      value: Array.isArray(c110) && c110.length
        ? (item[2] ? item[2](c110[0].data[item[1]]) : c110[0].data[item[1]])
        : '?',
      rawvalue: Array.isArray(c110) && c110.length ? c110[0].data[item[1]] : 0,
    }));
    const done = Array.isArray(c110) && c110.length ? Math.floor(100 - 100 * c110[0].data['total-remain'] / c110[0].data['total-time']) : 0;
    const series = [done];
    // console.log(series);
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">
            Промывка фильтра
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Table>
            <TableBody>
              {
                rows1.map(item => (
                  <TableRow key={item.key}>
                    <TableCell>{item.header}</TableCell>
                    <TableCell>{item.value}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <Grid container justify="center">
          {
            ['Промывка', 'Следующая стадия'].map((item, index) => (
              <AjaxButton
                id={this.props.id}
                code={111}
                style={{ marginTop: '12px', marginRight: '12px', width: '200px' }}
                color="primary"
                key={item}
                disabled={index === 0 ? active : !active}
              >
                {item}
              </AjaxButton>
            ))
          }
          </Grid>
          { active && (
            <Table>
              <TableBody>
                {
                  rows.map(item => (
                    <TableRow key={item.key}>
                      <TableCell>{item.header}</TableCell>
                      <TableCell>{item.value}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          )}
        </Grid>
        { active && (
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h6">
                  Стадии промывки
                </Typography>
                <RegenChart cycles={cycles} status={status} cycleCodes={cycleCodes} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h6">
                  Промывка фильтра
                </Typography>
                <Chart
                  options={options}
                  type="radialBar"
                  series={series}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(DeviceRegen);
// vim: ts=2 sw=2 et :
