import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MyPaper from './MyPaper';
import AjaxComponent, { API } from './AjaxComponent';


const styles = theme => ({
  tabsRoot: {
    backgroundColor: theme.palette.primary.main,
  },
  tabsSecond: {
    backgroundColor: theme.palette.primary.lightest,
    color: '#fff',
  },
});

/**
 * Список клапанов (дилерская часть).
 */
class HolderList extends React.Component {
  state = {
    holders: [],
    term: '',
    totalCount: 1,
    page: 0,
    pages: 1,
    perPage: 15,
    editId: false,
    editValue: false
  };

  async componentDidMount() {
    await this.feedData();
  }

  /**
   * Функция извлечения данных.
   *
   * Считывает из API одну страницу перечня собственников с учетом подстроки поиска, 
   * при необходимости изменения номер страницы, кол-во на странице или подстрока 
   * поиска задается в аргументах функции.
   */
  async feedData(term = false, page = false, perPage = false) { // false is no update
    if (page === false) page = this.state.page;
    if (perPage === false) perPage = this.state.perPage;
    if (term === false) term = this.state.term;
    this.setState({perPage, term}); // part of state that can be changed immediately
    const r = await API.request(API.holder('', term, page + 1, perPage));
    // API.rateLimitParse(r.headers);
    const [total, newPage, pages] = API.paginationParse(r.headers);
    this.setState({totalCount: total, page: newPage - 1, pages});
    const holders = await r.json();
    if (Array.isArray(holders)) this.setState({ holders });
  }

  /**
   * Обработчик на изменение пользователем поисковой строки.
   */
  handleTermChange(term) {
    if (term.length === 0 || term.length > 2) {
      this.feedData(term);
    } else {
      this.setState({ term })
    }
  }

  async updateHolderName() {
    const { editId, editValue } = this.state;
    this.setState({ editId: false });
    console.log(editId, editValue);
    const res = await API.req(API.holder(editId), 'PUT', { name: editValue })
    if (res.id === editId) {
      console.log('RES', res);
      let { holders } = this.state;
      holders = holders.map(h => h.id === res.id ? { ...h, name: res.name } : h);
      this.setState({ holders });
    }
  }

  /**
   * Основная отрисовывающая функция компонента.
   */
  render() {
    const { holders, term, editId, editValue, totalCount, page, perPage } = this.state;
    const fholders = holders;
    return (
      <MyPaper {...this.props} className="mac-list paper">
        <Typography variant="h6" className="mac-list__header"> Список пользователей </Typography>
        <TextField
          label="Поиск (мин. 3 символа)"
          onChange={event => this.handleTermChange(event.target.value)}
          value={term}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" />
              <TableCell align="center">Наименование или код</TableCell>
              <Hidden xsDown>
                <TableCell align="center">Email пользователей</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              fholders.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      onClick={() => this.props.onSelect(item.id)}
                    >
                      Перейти
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {
                      editId === item.id ? (
                        <TextField value={editValue === false ? item.name : editValue}
                          onChange={(event) => this.setState({ editValue: event.target.value }) }
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton onClick={() => this.updateHolderName()} >
                                  <KeyboardReturnIcon />
                                </IconButton>
                              </InputAdornment>
                          }}
                        />
                      ) : (
                        <>
                        {item.name}
                        <IconButton
                          onClick={() => this.setState({ editId: item.id })}
                        >
                          <EditIcon />
                        </IconButton>
                        </>
                      )
                    }
                  </TableCell>
                  <Hidden xsDown>
                    <TableCell align="center">
                      { item.users.map(item => item.email).join(', ') }
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  rowsPerPageOptions={[15, 25]}
                  rowsPerPage={perPage}
                  count={totalCount}
                  onChangePage={(e, newPage) => this.feedData(term, newPage, false)}
                  onChangeRowsPerPage={e => this.feedData(term, false, e.target.value)}
                />
              </TableRow>
          </TableFooter>
        </Table>
      </MyPaper>
    );
  }
}

HolderList.propTypes = {
};

export default withStyles(styles)(HolderList);

// # vim: ts=2 sw=2 et :
