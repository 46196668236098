import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import DevicesTabs from './DevicesTabs';
import OneDeviceTabs from './OneDeviceTabs';

import { API } from './AjaxComponent';

const styles = theme => ({
  root: {
  },
  tabsRoot: {
    backgroundColor: theme.palette.primary.main,
  },
});

/**
 * Блок вкладок, переключающих по изделиям.
 * На каждой странице отображает DeviceTabs
 * со вложенными вкладками клапанов для текущего изделия.
 */
class AssembliesTabs extends React.Component {
  state = { value: 0 };

  /**
   * Функция инициализации компонента
   * (вызывается при появлении на странице)
   */
  async componentDidMount() {
    await this.fetchData();
  }

  /** Обработчик изменения текущей вкладки */
  handleChange = (event, value) => {
    this.setState({ value });
  };

  /** 
   * Извлечение перечня изделий с помощью RESTful API,
   * обновление состояния компонента по факту получения списки.
   * Позволяет фильтровать по определенному собственнику изделия
   * (т.к. для администратора и дилера API-запрос выдаст изделия,
   * принадлежащие разным собственникам).
   */
  async fetchData() {
    const { holderId, assembly } = this.props;
    const res = await API.reqAll(API.assembly());
    const data = holderId ? Array.isArray(res) && res.filter(item => item.holder_id === holderId) : res;
    this.setState(assembly ? {
      value: data.findIndex(item => item.id === assembly),
      data,
    } : { data });
  }

  /** Основная функция отрисовки компонента. */
  render() {
    const { classes, assembly, device } = this.props;
    const { value, data } = this.state;
    const tabs = data
      ? data.map((elem, index) => <Tab label={elem.name} value={index} key={elem.id} />)
      : [];
    return (
      <div className={classes.root}>
        {
          !assembly && device ? (
            <OneDeviceTabs id={device} name={device} />
          ) : (
            <>
              <Tabs
                value={value}
                onChange={this.handleChange}
                classes={{ root: classes.tabsRoot }}
                style={{ color: '#fff' }}
              >
                {tabs}
              </Tabs>
              {
              data && data[value] && (
              <DevicesTabs
                info={data[value]}
                key={data[value].id}
                device={device}
              />
              )
            }
            </>
          )
        }
      </div>
    );
  }
}

AssembliesTabs.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  assembly: PropTypes.number,
  device: PropTypes.string,
  holderId: PropTypes.number,
};

AssembliesTabs.defaultProps = {
  holderId: null,
  assembly: null,
  device: null,
};

export default withStyles(styles)(AssembliesTabs);
// vim: ts=2 sw=2 et :
