import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = () => ({});

class InfoBox extends React.Component {
  state = {};

  render() {
    const { data, onSave } = this.props;
    return (
      <>
      <Table>
        <TableBody>
          {
            data.filter(item => item.field || item.value !== '').map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                {
                  !item.field? item.value :
                  <TextField onChange={
                    event => this.setState({ [item.field]: event.target.value })
                  } value={item.field in this.state ? this.state[item.field] : item.value} />
                }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      { onSave && Object.keys(this.state).length > 0 && (
        <Button onClick={() => onSave(this.state)}>Сохранить изменения</Button>
      ) }
      </>
    );
  }
}

export default withStyles(styles)(InfoBox);

// vim: ts=2 sw=2 et :
