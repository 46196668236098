import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MyPaper from './MyPaper';
import InfoBox from './InfoBox';
import { API } from './AjaxComponent'

/**
 * Footer приложения. Все данные для отображения передаются через props.
 */
class FooterBox extends Component {
  state = { dealer: false };

  /**
   * Обработчик создания компонента.
   * В зависимости от типа активного собственника, запрашивает
   * либо coбственные, либо вышестоящие дилерские настройки.
   */
  async componentDidMount() {
    const path = this.props.holder.path.split('.');
    const dealer_id = this.props.isDealer ? path[path.length - 1] : path[path.length - 2];
    const dealer = await API.req(API.property(dealer_id, 0, 0, 0, ['company', 'address', 'worktime', 'email', 'phone']));
    this.setState({ dealer, dealer_id });
  }

  async updateProperties(holder_id, userProps) {
    await Promise.all(
      Object.entries(userProps).map(async ([name, value]) => {
        await API.req(API.property(), 'POST', { holder_id, name, value });
        console.log('DONE', [name, value]) // FIXME: remove after tests
      })
    )
    await this.componentDidMount()
  }

  /** Основная функция отрисовки компонента. */
  render() {
    const { isDealer } = this.props;
    const { dealer } = this.state; // dealer info we have in properties
    const fields = [
      ['Компания', 'company'],
      ['Адрес', 'address'],
      ['Часы работы', 'worktime'],
      ['Email', 'email'],
      ['Телефон', 'phone'],
    ].map(([title, field], index) => ({
      title, field: isDealer && field, value: dealer[field] || ''
    }));
    console.log(fields)
    return (
      <MyPaper {...this.props}>
        <Typography variant="h6">Контактная информация</Typography>
        <Grid container>
          <Grid item xs={10}>
            <InfoBox data={fields} onSave={isDealer && (userChanges => this.updateProperties(this.state.dealer_id, userChanges))} />
          </Grid>
          {
            this.props.isDealer && false && ( // temporarily disabled
              <Grid item xs={2}>
                <Button variant="outlined" style={{ float: 'right' }}>
                        Добавить логотип
                </Button>
              </Grid>
            )
          }
        </Grid>
      </MyPaper>
    );
  }
}

export default FooterBox;
// vim: ts=2 sw=2 et :
