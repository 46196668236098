import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';

const styles = () => ({});

const commonOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      format: 'dd.MM.yyyy',
    },
    type: 'datetime',
  },
  // title: {
  //   text: 'Расход воды, м3/час',
  //   align: 'left',
  // },
};

/**
 * График дневного расхода воды на базе ApexCharts.
 * Все данные для отображения пробрасываются через props
 * из внешнего компронента, т.е. данный компонент сам
 * никаким образом не производит запросы к API,
 * и не имеет внутреннего состояния.
 */
class DeviceDayChart extends React.PureComponent {
  /** Основная функция отрисовки компонента. */
  render() {
    const { name='Расход', data=[] } = this.props;
    const { theme } = this.props;
    const series = [{ name, data }];
    let options = commonOptions;
    options.colors = [ theme.palette.primary.light ];
    return (
      <Chart
        options={options}
        series={series}
        type="area"
        height="250"
      />
    );
  }
}

export default withTheme(DeviceDayChart);
// export default withStyles(styles)(DeviceDayChart);

// vim: ts=2 sw=2 et :
