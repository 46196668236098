import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AjaxComponent from './AjaxComponent';


const styles = theme => ({
  cssLabel: {
    fontSize: '13px',
    transform: 'translate(14px, 12px) scale(1)',
    '&$cssFocused': {
      color: theme.palette.primary.lightest,
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    height: theme.spacing.unit * 4.5,
    paddingTop: 0,
    paddingBottom: 0,
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.primary.lightest,
    },
    '&$cssOutlinedTextarea': {
      height: 'auto',
    },
  },
  notchedOutline: {},
  cssOutlinedTextarea: {
  },
  textInput: {
    paddingTop: theme.spacing.unit * 1.125,
    paddingBottom: theme.spacing.unit * 1.125,
    fontSize: '13px',
  },
  formField: {
    marginTop: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit,
  },
});


class ModalFormField extends React.Component {
    state = {
      selectValues: this.getSelectValues(),
      data: [],
    };

    componentDidMount() {
      const { item } = this.props;
      if (item.feedUrl) {
        AjaxComponent.fetch2('data', item.feedUrl, (id, data) => this.setState({ data }));
      } else if (item.options) {
        this.setState({ data: item.options.map(i => ({ id: i, name: i })) });
      }
    }

    getSelectValues() {
      const { selectValues } = this.props;
      return selectValues;
    }

    handleChangeSelectValues = name => (event) => {
      const { selectValues } = this.state;
      selectValues[name] = event.target.value;
      this.setState({ selectValues });
    }

    render() {
      const { classes, item } = this.props;
      const { selectValues, data } = this.state;
      // console.log(data);

      switch (item.type) {
        case 'select':
          return (
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formField}
            >
              <InputLabel
                htmlFor={`outlined-${item.name}`}
                classes={{
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                }}
              >
                {item.label}
              </InputLabel>
              <Select
                native
                value={selectValues[item.name] || item.options[0]}
                onChange={this.handleChangeSelectValues(item.name)}
                IconComponent={() => (
                  <ExpandMore className="select-expand-more" fontSize="small" />
                )}
                input={(
                  <OutlinedInput
                    name={item.name}
                    labelWidth={item.labelWidth}
                    classes={{
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      input: classes.textInput,
                    }}
                    id={`outlined-${item.name}`}
                  />
                            )}
              >
                <option value="" />
                {data.map((option, index) => (
                  <option value={option.id} key={option.id}>{ option.name }</option>
                ))
                            }
              </Select>
            </FormControl>
          );
        case 'defined':
          return (
            <p className="modal-custom__value">
              {item.label}
              {': '}
              {item.value}
              { item.edit && ( <i className="edit-icon" />) }
            </p>
          );
        default:
          return (
            <TextField
              label={item.label}
              value={selectValues[item.name] || ''}
              onChange={this.handleChangeSelectValues(item.name)}
              margin="normal"
              fullWidth
              multiline={item.type === 'textarea'}
              variant="outlined"
              rows={4}
              type={item.type === 'search' ? item.type : 'text'}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.textInput,
                  multiline: classes.cssOutlinedTextarea,
                },
              }}
              className={['search', 'add', 'textarea'].indexOf(item.type) >= 0 ? `input-${item.type}` : classes.formField}
            />
          );
      }
    }
}

ModalFormField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  item: PropTypes.shape({}).isRequired,
  selectValues: PropTypes.shape({}),
};

ModalFormField.defaultProps = {
  selectValues: {},
};

export default withStyles(styles)(ModalFormField);
// vim: ts=2 sw=2 et :
