import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles, ThemeProvider, createMuiTheme }
  from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AssembliesTabs from './AssembliesTabs';
import AjaxComponent from './AjaxComponent';
import { HeadClassic, HeadMUI } from './Heads';
import FooterBox from './FooterBox';
import MyPaper from './MyPaper';
import MacList from './MacList';
import HolderList from './HolderList';
import Client from './Client';
// import styles from './Styles';
import './html.css';
import { UserContext } from './UserContext';
import WarningBox from './WarningBox';
import { domain } from './Domains'

const styles = () => ({});
const oe = domain() === 1

/**
 * Material-UI тема оформления приложения.
 * Палитра различная в зависимости от текущего домена.
 */
const theme = createMuiTheme({
  typography: {
    fontSize: 14, // the default
  },
  palette: {
    primary: {
      // lightest: '#54acdf',
      // azure: '#459cce',
      // light: '#5b89cb',
      // main: '#225c9a',
      // dark: '#00336b',
      lightest: oe ? '#54acdf' : '#0f8d9b',
      azure: oe ? '#459cce' : '#0f8d9b',
      light: oe? '#5b89cb' : '#0f8d9b',
      main: oe ? '#225c9a' : '#144a6b',
      dark: oe ? '#00336b' : '#144a6b',
      contrastText: '#ffffff',
      // main: '#0f8d9b', // light co
      // main: '#144a6b', // dark co
    },
    secondary: {
      light: '#ecfaff',
      main: '#bac7d2',
      dark: '#8a96a1',
      gray: '#888888',
      contrastText: '#000000',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
  },
});


const MODE_DEVICES = 0;
const MODE_HOLDERS = 1;
const MODE_INFO = 2;

/**
 * Основной класс React-приложения.
 */
class App extends React.Component {
  state = {
    key: '',
    mode: MODE_DEVICES,
    user: false,
    identity: '',
    password: '',
    message: '',
    holder: null,
    activeAssembly: null,
    activeDevice: null,
  };

  /**
   * Обработчик создания компонента.
   * извлекает API-ключ из localStorage и опраишвает API
   * о текущем пользователе
   */
  componentDidMount() {
    if (localStorage.getItem('token')) {
      const t = localStorage.getItem('token');
      this.keySet(t)
    } else {
      this.queryUser();
    }
  }

  /**
   * Запрос к REST API информации о текущем пользователе
   */
  queryUser() {
    AjaxComponent.fetch2(
      'user',
      AjaxComponent.apiUserGet(),
      (id, data) => this.userGet(id, data),
    );
  }

  /**
   * Сохранение API key в state
   */
  keySet(t) {
    AjaxComponent.setToken(t);
    this.queryUser();
    this.setState({ key: 'set' });
  }

  userGet(id, data) {
    this.setState({ user: data });
    // redirects for users not logged at 'theirs' domain
    if (data && data.holder && window.location.host.indexOf('local') === -1) {
      const path = data.holder.path.split('.')
      if (path[1] === '171') {
        if (!oe)
          window.location = 'https://online.ekodar.ru'
      }
      if (path[1] === '172') {
        if (oe)
          window.location = 'https://clackonline.ru'
      }
    }
  }

  /**
   * Обработчик при смене пользователем текущего собственника (hodler).
   * Извлекает информацию из API.
   */
  handleHolderSelect(id) {
    // console.log(id);
    AjaxComponent.fetch2(
      'holder',
      AjaxComponent.apiHolderGet(id),
      (_, holder) => {
        // console.log(holder);
        this.setState({ holder, activeAssembly: null, activeDevice: null });
      },
    );
  }

  /**
   * Обработчик действия "Перейти к устройству"
   */
  handleGoToDevice(device) {
    // console.log('going to', device.id, device.assemblies);
    this.setState({
      mode: MODE_HOLDERS,
      holder: device.holder,
      activeAssembly: device.assemblies.length > 0 ? device.assemblies[0].id : 0,
      activeDevice: device.id,
    });
  }

  /**
   * Рендеринг переключателей клапаны/пользователи
   */
  renderSwitches(mode) {
    return (
      <MyPaper>
        <Button
          onClick={() => this.setState({ mode: MODE_DEVICES })}
          variant={mode === MODE_DEVICES ? 'contained' : 'outlined'}
        >
          Клапаны
        </Button>
        <Button
          onClick={() => this.setState({ mode: MODE_HOLDERS })}
          variant={mode === MODE_HOLDERS ? 'contained' : 'outlined'}
        >
          Пользователи
        </Button>
        <Button
          onClick={() => this.setState({ mode: MODE_INFO })}
          variant={mode === MODE_INFO ? 'contained' : 'outlined'}
        >
          Информация
        </Button>
      </MyPaper>
    );
  }

  /**
   * Рендеринг дилер-специфичных элементов
   */
  renderDealer(classes, mode) {
    const { holder, activeAssembly, activeDevice, user } = this.state;
    return mode === MODE_HOLDERS ? (
      <>
        {this.renderSwitches(mode)}
        <div className={classes.breadcrumbs}>
          <Link href="/">Главная</Link>
          {' > '}
          <Link href="/">Профиль клиента</Link>
        </div>
        {
          holder ? (
            <>
              <Client
                holder={holder}
                onClick={() => this.setState({ holder: null, mode: MODE_HOLDERS })}
              />
              <Grid item xs={12}>
                <MyPaper className={classes.paper}>
                  <Typography variant="h6"> Система очистки воды </Typography>
                  <AssembliesTabs
                    holderId={holder.id}
                    assembly={activeAssembly}
                    device={activeDevice}
                  />
                </MyPaper>
              </Grid>
            </>
          ) : <HolderList onSelect={id => this.handleHolderSelect(id)} />
        }
      </>
    ) : mode === MODE_DEVICES ? (
      <>
        {this.renderSwitches(mode)}
        <MacList onGoToDevice={device => this.handleGoToDevice(device)} />
      </>
    ) : ( 
      <>
        {this.renderSwitches(mode)}
        <FooterBox isDealer={true} holder={user.holder} />
      </>
    );
  }

  /**
   * Рендеринг юзер-специфичных элементов
   */
  renderUser(user) {
    return (
      <>
        <Grid item xs={12}>
          <MyPaper>
            <Typography variant="h6">Предупреждения</Typography>
            <WarningBox />
          </MyPaper>
        </Grid>
        <Grid item xs={12}>
          <MyPaper>
            <Typography variant="h6"> Система очистки воды </Typography>
            <AssembliesTabs />
          </MyPaper>
        </Grid>
        <Grid item xs={12}>
          <FooterBox isDealer={false} holder={user.holder} />
        </Grid>
      </>
    );
  }

  login() {
    this.setState({ message: 'Выполняется вход в систему...' });
    const { identity, password } = this.state;
    fetch(
      AjaxComponent.apiUserLogin(),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ identity, password }),
      },
    )
      .then(data => data.json())
      .then((data) => {
        if (data.token) {
          this.keySet(data.token);
          localStorage.setItem('token', data.token);
          this.setState({ identity: '', password: '', message: '' });
        } else {
          this.setState({ message: data.message });
        }
      });
  }

  /** Основная функция отрисовки компонента. */
  render() {
    const { classes } = this.props;
    const {
      mode, user, message, identity, password,
    } = this.state;
    if (!localStorage.getItem('token') && window.location.host.indexOf('local') === -1) {
      // FIXME hotfix for login page redirect
      window.location = window.location.protocol + '//' + window.location.host + '/site/login';
    }
    return (
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
          {user
            && (
            <UserContext.Provider value={user}>
              <HeadClassic onClick={() => {
                this.keySet(null);
                localStorage.removeItem('token');
                this.userGet(null, false);
              }}
              />
              {
                UserContext.isDealer(user) ? (
                  <Grid item xs={12} className={classes.wrapper}>
                    { this.renderDealer(classes, mode) }
                  </Grid>
                ) : this.renderUser(user)
              }
            </UserContext.Provider>
            )
          }
        </Container>
        <div>
          <Dialog
            open={!user}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
            >
                  Вход в систему
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                    Введите Ваш адрес Email и пароль, заданный Вами при активации учетной записи.
              </DialogContentText>
              <TextField
                label="Email адрес"
                type="email"
                onChange={event => this.setState({ identity: event.target.value })}
                fullWidth
                margin="dense"
                value={identity}
              />
              <TextField
                label="Пароль"
                type="password"
                onChange={event => this.setState({ password: event.target.value })}
                fullWidth
                margin="dense"
                value={password}
              />
              {
                  message && <DialogContentText>{message}</DialogContentText>
              }
              <Link
                href="#"
                onClick={() => {
                  window.location = `https://${window.location.host}/user/sign-in/request-password-reset`;
                }
              }
              >
Восстановление пароля
              </Link>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => this.login()}
                color="primary"
              >
                    Вход
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(App);

// vim: ts=2 sw=2 et :
