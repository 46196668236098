import React from 'react';

const intOrStr = (text, validStrings) => {
    return validStrings.includes(text) ? text : parseInt(text)
}

const config = [
  {
    label: 'Тип системы',
    type: 'select',
    options: [
      { value: 'Softening' },
      { value: 'Filtering' },
    ],
    code: 202,
    getter: data => data['Service-Settings'].Type,
    setter: value => ({ Type: value }),
    id: 'system-type',
  },
  {
    label: 'Стадии регенерации',
    type: 'regens',
    code: 206,
    getter: data => data['Regen-Settings']['Regen Cycles'].filter(option => option.Cycle !== 'Open'),
    setter: value => ({ 'Regen Cycles': value }),
    id: 'regen-settings',
  },
  {
    label: 'Обменная емкость системы',
    type: 'text',
    code: 202,
    getter: data => data['Service-Settings']['Grains Capacity'],
    setter: value => ({ 'Grains Capacity': parseInt(value) }),
    id: 'grains-cap',
  },
  {
    label: 'Пропорциональное заполнение бака',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'Fill' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Proportional Mode'],
    setter: value => ({ 'Proportional Mode': value }),
    id: 'prop-mode',
  },
  {
    // labelObj: <>Ресурс системы (м<sup>3</sup>)</>,
    labelObj: <>Ресурс системы (л)</>,
    type: 'text',
    // options: [ // For future
    //   { label: 'Off', value: 'Off' },
    //   { label: 'On', value: 'On' },
    //   { label: 'Auto', value: 'Auto' },
    // ],
    code: 202,
    getter: data => data['Service-Settings'].Capacity,
    setter: value => ({ Capacity: intOrStr(value, ['Off', 'Auto']) }),
    id: 'cap',
  },
  {
    label: 'Режим промывки',
    type: 'select',
    options: [
      { value: 7 },
      { value: 28 },
      { value: 'Off' },
    ],
    code: 202,
    getter: data => data['Service-Settings']['Day Override Type'],
    setter: value => ({ 'Day Override Type': value }),
    id: 'override-type',
  },
  {
    label: 'Тип промывки',
    type: 'select',
    options: [
      { value: 'Immediate' },
      { value: 'Delayed + Immediate' },
      { value: 'Delayed' },
      { value: 'Delayed 2x' },
      { value: 'Delayed 3x' },
      { value: 'Delayed 4x' },
      { value: 'Delayed 6x' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Regen Type'],
    setter: value => ({ 'Regen Type': value }),
    id: 'regen-type',
  },
  {
    label: 'Настройка режима работы релейного выхода 1',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'Regen Time' },
      { value: 'Volume' },
      { value: 'Regen Volume' },
      { value: 'Error Monitor' },
      { value: 'Regen' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Relay 1 Mode'],
    setter: value => ({ 'Relay 1 Mode': value }),
    id: 'relay-1-mode',
  },
  {
    label: 'Условия работы релейного выхода 1',
    type: 'text',
    code: 203,
    getter: data => data['Configuration-Settings']['Relay 1 Setpoint'],
    setter: value => ({ 'Relay 1 Setpoint': parseInt(value) }),
    id: 'relay-1-setpoint',
  },
  {
    label: 'Время работы релейного выхода 1',
    type: 'text',
    code: 203,
    getter: data => data['Configuration-Settings']['Relay 1 Duration'],
    setter: value => ({ 'Relay 1 Duration': parseInt(value) }),
    id: 'relay-1-duration',
  },
  {
    label: 'Настройка режима работы релейного выхода 2',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'Regen Time' },
      { value: 'Volume' },
      { value: 'Regen Volume' },
      { value: 'Regen' },
      { value: 'Cycle' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Relay 2 Mode'],
    setter: value => ({ 'Relay 2 Mode': value }),
    id: 'relay-2-mode',
  },
  {
    label: 'Условия работы релейного выхода 2',
    type: 'text',
    code: 203,
    getter: data => data['Configuration-Settings']['Relay 2 Setpoint'],
    setter: value => ({ 'Relay 2 Setpoint': parseInt(value) }),
    id: 'relay-2-setpoint',
  },
  {
    label: 'Время работы релейного выхода 2',
    type: 'text',
    code: 203,
    getter: data => data['Configuration-Settings']['Relay 2 Duration'],
    setter: value => ({ 'Relay 2 Duration': parseInt(value) }),
    id: 'relay-2-duration',
  },
  // {
  //   label: '',
  //   type: '',
  //   code: '',
  //   getter: data => data[''],
  //   setter: value => ({ value }),
  // },
];

export default config;
