import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import OneDeviceTabs from './OneDeviceTabs';

const styles = () => ({});

/**
 * Блок вкладок, переключающих по клапанам.
 *
 * На каждой странице отображает OneDeviceTabs
 * со вложенными вкладками различных действий с текущим клапаном.
 */
class DevicesTabs extends React.Component {
  /** Состояние React-компонента. */
  state = { value: 0 };

  /**
   * Функция инициализации компонента
   * (вызывается при появлении на странице)
   */
  componentDidMount() {
    const { info = [], device } = this.props; // FIXME: only devices needed under info, refactor
    if (device) {
      const index = info.devices.findIndex(item => item.id === device);
      this.setState({ value: index === -1 ? 0 : index });
    }
  }

  /** Обработчик изменения текущей вкладки */
  handleChange = (event, value) => {
    this.setState({ value });
  };

  /** Основная функция отрисовки компонента. */
  render() {
    const { info = [] } = this.props;
    const { value } = this.state;
    return (
      <Grid container>
        <Grid item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <Typography variant="h6">Состав изделия</Typography>
        </Grid>
        <Grid item>
          {
            info.devices.map((dev, i) => (
              <Button
                variant="contained"
                color={i === value ? 'primary' : 'secondary'}
                style={{ margin: '12px' }}
                onClick={event => this.handleChange(event, i)}
                key={dev.name}
              >
                {`Фильтр ${i + 1}`}
              </Button>
            ))
          }
        </Grid>
        <Grid item xs={12}>
          { info.devices[value] ? <OneDeviceTabs name={info.devices[value].name} id={info.devices[value].id} key={info.devices[value].id} /> : 'no content' }
        </Grid>
      </Grid>
    );
  }
}

DevicesTabs.propTypes = {
  info: PropTypes.shape({}).isRequired,
  device: PropTypes.string,
};

DevicesTabs.defaultProps = {
  device: false,
};

export default withStyles(styles)(DevicesTabs);

// vim: ts=2 sw=2 et :
