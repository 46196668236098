import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { UserContext } from './UserContext';

const logoUrl = 'https://' + window.location.host + '/site/logo';
// const logoUrl = 'http://localhost:8080/site/logo';

/**
 * Header ("шапка") приложения с логотипом, контактной информацией
 * и информацией о текущем пользователе
 */
export class HeadClassic extends React.Component {
  static contextType = UserContext;

  render() {
    const user = this.context;
    return (
      <Grid container>
        <Grid item xs={12} className="header">
        <header>
          <img className="header__logo" src={logoUrl} alt="logo" />
      <Hidden smDown>
          <div className="header__caption">
            Система контроля фильтров
          </div>
      </Hidden>
      <Hidden xsDown>
          <div className="header__phone">8 (800) 775 67 02</div>
      </Hidden>
      <Hidden xsDown>
          <div className="header__caption">
      <Link href="/manual-redirect">Руководство пользователя</Link>
          </div>
      </Hidden>
          <Button className="header__logout" variant="outlined" onClick={this.props.onClick}>
            Выйти
          </Button>
      <Hidden xsDown>
          <div className="header__personal">
            <span className="header__id">Ваш ID: {user && user.holder ? user.holder.id : 'guest'}</span>
          </div>
      </Hidden>
        </header>
      </Grid>
      <Hidden smUp>
      <Grid item xs={12}>
      <Link href="/manual-redirect">Руководство пользователя</Link>
      </Grid>
      </Hidden>
      </Grid>
    );
  }
}

const styles = {
  grow: { flexGrow: 1 },
};

export const HeadMUI = (props) => {
  const { classes={grow:'grow'}, onClick } = props;
  return (
    <AppBar position="static" color="default">
      <Toolbar variant="dense">
        <img src={logoUrl} alt="logo" />
        <Typography className={classes.grow} style={{paddingLeft: '25px'}} variant="body2">
          Система контроля фильтров
        </Typography>
        <Typography className={classes.grow} variant="h6">
          8 (800) 775 67 02
        </Typography>
        <Card className={classes.grow}>
          <CardContent>
            <Typography variant="body1">
              Ваш ID: 21344
            </Typography>
          </CardContent>
        </Card>
        <Button variant="outlined" className={classes.grow} onClick={onClick}> Выйти </Button>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(HeadMUI);
// vim: ts=2 sw=2 et :
