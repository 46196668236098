import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import AjaxComponent from './AjaxComponent';


const styles = () => ({});


class DeviceArchive extends AjaxComponent {
  state = {
    days: 7, page: 0, perPage: 15, totalCount: 0, stat: [], from: '', to: '', data: new Map(), series1: [], series2: [],
  };

  fetchPage(page = 0) {
    const { id } = this.props;
    const {
      days, perPage, from, to,
    } = this.state;
    this.fetchOne(page, AjaxComponent.apiStatisticGet(id, from, to, days, page + 1, perPage));
  }

  headersFetched(headers) {
    const [totalCount, currentPage, pageCount] = AjaxComponent.paginationParse(headers);
    if (currentPage === 1) {
      for (let i = currentPage + 1; i <= pageCount; i += 1) {
        this.fetchPage(i - 1);
      }
    }
    this.setState({ totalCount });
  }

  dataFetched(id, fetchedData) {
    let { data } = this.state;
    if (Array.isArray(fetchedData) && fetchedData.length) {
      data.set(id, fetchedData);
      data = new Map(
        [...data].sort((a, b) => a[0] - b[0]),
      );
    }
    this.setState({ data });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      days, from, to, data, perPage,
    } = this.state;
    // FIXME: use Array.some()
    if (prevState.days !== days || prevState.from !== from || prevState.to !== to || prevState.perPage !== perPage) {
      // this will cause re-fetching all data
      this.setState({ data: new Map() }); // BAD
    }
    if (prevState.data !== data && data.size === 0) {
      this.fetchPage();
    }
  }

  componentDidMount() { // overriding default impl
    this.fetchPage();
  }

  render() {
    const {
      page, perPage, totalCount, data,
    } = this.state;
    const stat = data.get(page) || [];
    return (
      <Grid item xs={12}>
        <Table align="center">
          <TableHead>
            <TableRow>
              {['Дата', 'Расход воды', 'Расход реагента', 'Максимальный расход воды', 'Количество регенераций'].map(item => <TableCell align="center" key={item}>{item}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              stat.map(item => (
                <TableRow key={item.date}>
                  <TableCell align="center">{item.date}</TableCell>
                  <TableCell align="center">{item.usage === null ? 'нет данных' : item.usage}</TableCell>
                  <TableCell align="center">{4.25 * item.regens}</TableCell>
                  <TableCell align="center">{item.max_flow || 'нет данных'}</TableCell>
                  <TableCell align="center">{item.regens}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPageOptions={[15, 25]}
                rowsPerPage={perPage}
                count={totalCount}
                onChangePage={(e, newPage) => this.setState({ page: newPage })}
                onChangeRowsPerPage={e => this.setState({ perPage: e.target.value })}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
    );
  }
}

export default withStyles(styles)(DeviceArchive);
// vim: ts=2 sw=2 et :
