const config = [
  {
    label: 'Сброс до заводских настроек',
    type: 'button',
    code: 113,
    get_payload: false,
    payload: { level: 1 },
    id: 'factoryreset',
  },
  {
    label: 'Тип клапана',
    type: 'select',
    options: [
      { value: '1.0' },
      { value: '1.25' },
      { value: '1.5' },
      { value: '2.0' },
      { value: '1.0T' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Valve Type'],
    setter: value => ({ 'Valve Type': value }),
    id: 'valvetype',
  },
  {
    label: 'Тип счетчика',
    type: 'select',
    options: [
      { value: '1.0' },
      { value: '1.25' },
      { value: '1.5' },
      { value: '2.0' },
      { value: '3.0' },
      { value: '1.0R' },
      { value: '1.0T' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Meter 1 Size'],
    setter: value => ({ 'Meter 1 Size': value }),
    id: 'metertype',
  },
  {
    label: 'Настройка режима работы внешнего клапана 1',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'No Hard Water Bypass' },
      { value: 'Alternator A' },
      { value: 'Alternator B' },
      { value: 'System Controller' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Alt Mav Mode'],
    setter: value => ({ 'Alt Mav Mode': value }),
    id: 'altmav',
  },
  {
    label: 'Отложенное завершение промывки',
    type: 'select',
    options: [
      { value: 'Off' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Pre-Service Rinse'],
    setter: value => ({ 'Pre-Service Rinse': value }),
    id: 'prerinse',
  },
  {
    label: 'Промывка перед возвращением в сервис',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'On' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Delayed Rinse+Fill'],
    setter: value => ({ 'Delayed Rinse+Fill': value }),
    id: 'delayedrinse',
  },
  {
    label: 'Настройка режима работы (дополнительного) внешнего клапана 2',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'No Hard Water Bypass' },
      { value: 'Separate Source' },
      { value: 'Leakage Alarm' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Aux Mav Mode'],
    setter: value => ({ 'Aux Mav Mode': value }),
    id: 'auxmav',
  },
  {
    label: 'Внешний сигнал',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'Immediate' },
      { value: 'Delayed' },
      { value: 'Hold' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Auxiliary Input'],
    setter: value => ({ 'Auxiliary Input': value }),
    id: 'auxinput',
  },
  {
    label: 'Единицы жесткости',
    type: 'select',
    options: [
      { value: 'MEQ' },
      { value: 'FH' },
      { value: 'DH' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Hardness Units'],
    setter: value => ({ 'Hardness Units': value }),
    id: 'hardnessunits',
  },
  {
    label: 'Заполнение реагентного бака, единицы измерения',
    type: 'select',
    options: [
      { value: 'mins' },
      { value: 'kg' },
    ],
    code: 203,
    getter: data => data['Configuration-Settings']['Fill Units'],
    setter: value => ({ 'Fill Units': value }),
    id: 'fillunits',
  },
];
export default config;
