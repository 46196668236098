import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import AjaxComponent from './AjaxComponent';

const styles = {};

const disableTimeout = 30000;

/**
 * Кнопка, автоматически отправляющая запрос к REST API на MQTT-команду
 * к заданному клапану.
 */
class AjaxButton extends React.Component {
  state = { forceDisabled: false }

  timer = null

  static propTypes = {
    id: PropTypes.string.isRequired,
    code: PropTypes.number.isRequired,
    payload: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }

  static defaultProps = {
    payload: {},
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  /** Обработчик нажатия кнопки */
  handleClick() {
    const { id, code, payload } = this.props;
    AjaxComponent.apiMessagePost(id, code, payload);
    this.setState({ forceDisabled: true });
    this.timer = setTimeout(() => this.setState({ forceDisabled: false }), disableTimeout);
  }

  /** Основная функция отрисовки компонента. */
  render() {
    const { children, disabled, ...props } = this.props;
    const newDisabled = this.state.forceDisabled || disabled;
    return (
      <Button disabled={newDisabled} variant="contained" onClick={() => this.handleClick()} {...props}>{children}</Button>
    );
  }
}

export default withStyles(styles)(AjaxButton);
// vim: ts=2 sw=2 et :
