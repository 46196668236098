import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AjaxComponent from './AjaxComponent';
import DeviceFlowChart from './DeviceFlowChart';
import DeviceCapGauge from './DeviceCapGauge';
import DeviceStatsInner from './DeviceStatsInner';

const styles = () => ({});

/**
 * Блок вкладки "Статистика".
 * Отвечает за компоновку графиков, таблиц и кнопок переключения временного
 * интервала.
 */
class DeviceStats extends AjaxComponent {
  state = { interval: 30 };

  urls = {
  };

  /**
   * Обработчик переключения временного интервала пользователем
   * @param {number} interval Интервал вывода сообщений с кодом 100, в секундах
   */
  handleIntervalChange(interval) {
    AjaxComponent.apiMessagePost(this.props.id, 112, { Interval_100: interval, Count_100: 1000 });
    this.setState({ interval });
  }

  /**
   * Обработчик переключения временного интервала клапаном
   * @param {number} ms Интервал отставания в мс
   */
  handleIntervalGet(ms) {
    const interval = [1, 5, 10, 30].reduce((best, current) => (
      Math.abs(current - ms / 1000) < Math.abs(best - ms / 1000) ? current : best
    ));
    this.setState({ interval });
  }

  /**
   * Основная отрисовывающая функция компонента.
   * Извлекает из свойств и состояния необходимые данные для отрисовки.
   */
  render() {
    const { id } = this.props;
    const { interval } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Интервал обновления
          </Typography>
          <Grid container justify="center">
          {
            [1, 5, 10, 30].map(i => (
              <Button
                variant="contained"
                onClick={() => this.handleIntervalChange(i)}
                color={interval === i ? 'primary' : 'secondary'}
                key={i}
                style={{ marginTop: '12px', marginRight: '12px', width: '128px' }}
              >
                {`${i} секунд${i === 1 ? 'а' : ''}`}
              </Button>
            ))
          }
        </Grid>
        </Grid>
        <Grid item xs={12} sm={7} md={8}>
          <Typography variant="h6">
            Расход воды, л/мин
          </Typography>
          <DeviceFlowChart id={id} interval={interval || 10} onIntervalGet={i => this.handleIntervalGet(i)} />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <Typography variant="h6">
            Оставшийся ресурс
          </Typography>
          <DeviceCapGauge id={id} />
        </Grid>
        <DeviceStatsInner id={id} />
      </Grid>
    );
  }
}

export default withStyles(styles)(DeviceStats);

// vim: ts=2 sw=2 et :
