import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import AjaxButton from './AjaxButton';
import ConfigField from './ConfigField';
import RegenSettings from './RegenSettings';
import tr from './i18n';

const styles = theme => ({});
// const styles = theme => ({
//   buttonLight: {
//     backgroundColor: theme.palette.primary.light,
//   },
//   button: {
//     margin: '30px 0 0',
//     fontSize: '13px',
//     height: '36px',
//     background: theme.palette.primary.lightest,
//     '&:hover': {
//       background: theme.palette.primary.azure,
//     },
//     color: theme.palette.primary.contrastText,
//   },
//   btnOffset: {
//     marginLeft: '25px',
//   },
//   topConfig: {
//     marginTop: 0,
//   },
// });

/**
 * Содержимое отдельной вкладки с настройками клапана.
 */
class DeviceSettingsTab extends React.Component {
  /**
   * Состояние React-компонента.
   */
  state = {};

  /** Основная функция отрисовки компонента. */
  render() {
    const {
      id, config, params, userChange
    } = this.props;
    return (
      <Grid container>
          {
            config.map((item) => {
              if (item.type === 'button') {
                return (
                  <Grid key={item.id} item xs={item.xs || 12} md={item.md || 12}>
                    <AjaxButton
                      id={id}
                      code={item.code}
                      payload={item.payload}
                      variant="contained"
                      color="primary"
                      style={item.xs ? {}:{width: '260px', marginLeft: '32px', marginTop: '16px'}}
                    >
                      {item.label}
                    </AjaxButton>
                  </Grid>
                );
              }
              if (item.type === 'regens') {
                return (
                  <Grid key={item.id} item xs={12} md={12}>
                    <RegenSettings
                      cycles={params[item.code] ? item.getter(params[item.code]) : []}
                      userChange={value => userChange(item, value)}
                    />
                  </Grid>
                );
              }
              if (item.type === 'select') {
                return (
                  <Grid key={item.id} item xs={item.xs || 12} md={item.md || 6}>
                    <ConfigField
                      label={item.label}
                      select
                      color="primary"
                      id={item.id}
                      initialValue={params[item.code] ? item.getter(params[item.code]) : ''}
                      userChange={value => userChange(item, value)}
                    >
                      {item.options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label || tr(option.value, item.code)}
                        </MenuItem>
                      ))}
                    </ConfigField>
                  </Grid>
                );
              }
              // Any other case
              return (
                <Grid key={item.id} item xs={item.xs || 12} md={item.md || 6}>
                  <ConfigField
                    label={item.label}
                    labelObj={item.labelObj}
                    type="text"
                    color="primary"
                    id={item.id}
                    initialValue={params[item.code] ? item.getter(params[item.code]) : ''}
                    userChange={value => userChange(item, value)}
                  />
                </Grid>
              );
            })
        }
      </Grid>
    );
  }
}

DeviceSettingsTab.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  config: PropTypes.array.isRequired,
};

export default withStyles(styles)(DeviceSettingsTab);
// vim: ts=2 sw=2 et :
