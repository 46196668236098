import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';
import parseISO from 'date-fns/parseISO';

import AjaxComponent from './AjaxComponent';

/** Общие опции для ApexCharts */
const commonOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    // height: 250,
    // type: 'line',
    // zoom: {
    //   enabled: false,
    // },
    animations: {
      enabled: false,
    //   easing: 'linear',
      // dynamicAnimation: {
      //   speed: 2000,
      // },
    },
  },
  // dataLabels: {
  //   enabled: false,
  // },
  tooltip: {
    x: {
      format: 'HH:mm:ss',
    },
  },
  stroke: {
    curve: 'smooth',
  },
  markers: {
    size: 0,
  },
  // title: {
  //   text: 'Расход воды, м3/час',
  //   align: 'left',
  // },
  // grid: {
  //   row: {
  //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
  //     opacity: 0.5,
  //   },
  // },
  xaxis: {
    // min: 0,
    // max: 300,
    labels: {
      // format: 'HH:mm:ss',
      formatter: (value, timestamp) => {
        const d = new Date(timestamp);
        const hms = [d.getHours(), d.getMinutes(), d.getSeconds()].map((i) => i.toString()).map((s) => (s.length > 1 ? s : `0${s}`));
        return hms.join(':');
        // return d.toLocaleTimeString('ru-RU');
      },
    },
    type: 'datetime',
    // range: 600000,
  },
  // yaxis: { min: 0, max: 10 },
};

const secsLen = 600;
const pageSize = 25;

/**
 * График текущего расхода воды.
 * Извлекает данные для построения графика при необходимости
 * в несколько запросов (в случае, если требуется извлечь
 * несколько страниц данных).
 *
 * Для хранения данных в нужной временной последовательности
 * используется контейнер Map(), в который дописываются свежие
 * данные из интервала 10 min, и удаляются устаревшие.
 */
class DeviceFlowChart extends AjaxComponent {
  lastTime = null

  data = new Map()

  state = { series: [] };

  /**
   * Первоначальное извлечение данных.
   * Извлекает все данные, необходимые для построения графика "с нуля".
   */
  initialFetch(page = 1) {
    const url = AjaxComponent.apiMessageGet2(this.props.id, 100, '', '', secsLen, page, pageSize);
    this.fetchOne('test', url);
  }

  /**
   * Извлечение только новых данных по графику.
   * Извлекает новые данные, которые не были извлечены в ходе предыдущих
   * вызовов initialFetch / updatingFetch
   */
  updatingFetch(page = 1) {
    if (this.lastTime !== null) {
      const url = AjaxComponent.apiMessageGet2(this.props.id, 100, new Date(this.lastTime + 1).toISOString(), '', '', page, pageSize);
      this.fetchOne('test', url); // +1 for excluding overlap
    } else {
      this.initialFetch(page);
    }
  }

  /**
   * Извлечение данных о текущей странице и их количестве из HTTP-заголовков.
   * Для каждой еще не извлеченной страницы инициирует новый API-запрос.
   */
  headersFetched(headers) {
    const [totalCount, currentPage, pageCount] = AjaxComponent.paginationParse(headers);
    if (currentPage === 1) {
      for (let i = currentPage + 1; i <= pageCount; i += 1) {
        this.initialFetch(i);
      }
    }
  }

  timeIsInRange(timestr) {
    return this.lastTime - parseISO(`${timestr}`) < secsLen * 1000;
  }

  /**
   * Обработчик извлеченных данных.
   * Упаковывает поступившие данные в отсортированную должным образом
   * переменную data, затем использует ее для построения массива series,
   * в формате, требуемом ApexCharts для построения графика.
   */
  dataFetched(id, data) {
    // console.log(data);
    if (Array.isArray(data) && data.length) {
      // this.data.set(data[0].id, data.map(item => item.data));
      this.data.set(data[0].id, data);
      this.lastTime = Math.max(this.lastTime, parseISO(`${data[0].time}`));
      this.data = new Map(
        [...this.data]
          .sort((a, b) => b[0] - a[0])
          .filter((item) => this.timeIsInRange(item[1][0].time)),
      );
    }
    // console.log(new Date(this.lastTime), this.data);
    const series = [...this.data].reduce((acc, val) => (
      acc.concat(val[1]
        .filter((item) => this.timeIsInRange(item.time))
        .map((item) => (
          { x: parseISO(`${item.time}`), y: item.data['current-flow'] }
        )))
    ), []);
    // console.log(series);
    if (series.length > 1 && this.props.onIntervalGet) {
      this.props.onIntervalGet(series[0].x - series[1].x);
    }
    this.setState({ series: [{ name: 'Расход', data: series }] });
  }

  /**
   * Вызывается при первичном появлении компонента в приложении.
   * Инициирует первичный API-запрос на получение данных, а также
   * таймер на последующее обновление.
   */
  componentDidMount() {
    this.initialFetch();
    this.timer = setInterval(() => this.updatingFetch(), 1000 * this.props.interval);
  }

  /**
   * Обработчик изменения состояния компонента.
   *
   * Останавливает заново таймеры опросов данных.
   */
  componentDidUpdate(prevProps) {
    if (this.props.interval !== prevProps.interval) {
      clearInterval(this.timer);
      this.timer = setInterval(() => this.updatingFetch(), 1000 * this.props.interval);
    }
  }

  /**
   * Функция деинициализации компонента
   * (вызывается при удалении со страницы).
   *
   * Останавливает таймеры опросов данных.
   */
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * Основная отрисовывающая функция компонента.
   * Извлекает из свойств и состояния необходимые данные для отрисовки,
   * и передает их в нижележащий ApexCharts
   */
  render() {
    const { series } = this.state;
    const { theme } = this.props;
    const options = commonOptions;
    options.colors = [theme.palette.primary.light];
    return (
      <Chart
        options={options}
        series={series}
        type="line"
        height="250"
      />
    );
  }
}

export default withTheme(DeviceFlowChart);

// vim: ts=2 sw=2 et :
