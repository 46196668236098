import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DeviceDayChart from './DeviceDayChart';

import { API } from './AjaxComponent';

const styles = () => ({
});

/**
 * Внутренняя часть вкладки "Статистика", отвечающая за графики и таблицы
 * суточной статистики, и элементы навигации по временному интревалу.
 */
class DeviceStatsInner extends API {
  /**
   * Состояние React-компонента.
   * @prop {number} days Количество отображаемых суток
   * @prop {number} page Текущая страница таблицы
   * @prop {number} perPage Кол-во отображаемых на странице элементов
   * @prop {number} perPage Кол-во элементов всего
   * @prop {string} from Дата начала интервала
   * @prop {string} from Дата окончания интервала
   * */
  state = {
    days: 7, page: 0, perPage: 15, totalCount: 0, stat: [], from: '', to: '', data: new Map(), series1: [], series2: [],
    fill: null,
  }

  /**
   * Извлекает даные по отдельной странице выборки.
   * @prop {number} page Номер страницы.
   */
  fetchPage(page = 0) {
    const { id } = this.props;
    const {
      days, perPage, from, to,
    } = this.state;
    this.fetchOne(page, API.apiStatisticGet(id, from, to, days, page + 1, perPage));
  }

  /**
   * Извлечение данных о текущей странице и их количестве из HTTP-заголовков.
   * Для каждой еще не извлеченной страницы инициирует новый API-запрос.
   */
  headersFetched(headers) {
    const [totalCount, currentPage, pageCount] = API.paginationParse(headers);
    if (currentPage === 1) {
      for (let i = currentPage + 1; i <= pageCount; i += 1) {
        this.fetchPage(i - 1);
      }
    }
    this.setState({ totalCount });
  }

  /**
   * Обработчик извлеченных данных.
   * Упаковывает поступившие данные в отсортированную должным образом
   * переменную this.state.data, затем использует ее для построения
   * массивов this.state.series1 и this.state.series2,
   * в формате, требуемом ApexCharts для построения графика.
   */
  dataFetched(id, fetchedData) {
    let { data, fill } = this.state;
    if (Array.isArray(fetchedData) && fetchedData.length) {
      data.set(id, fetchedData);
      data = new Map(
        [...data].sort((a, b) => a[0] - b[0]),
      );
    }
    this.setState({ data });
    const series1 = [...data].reduce((acc, val) => (
      acc.concat(val[1].map(item => (
        { x: `${item.date}`, y: item.usage / 1000.0 }
      )))
    ), []);
    const series2 = [...data].reduce((acc, val) => (
      acc.concat(val[1].map(item => (
        { x: `${item.date}`, y: item.regens * fill }
      )))
    ), []);
    this.setState({ data, series1, series2 });
  }

  /**
   * Обработчик изменения состояния компонента.
   */
  componentDidUpdate(prevProps, prevState) {
    const {
      days, from, to, data, perPage,
    } = this.state;
    // FIXME: use Array.some()
    if (prevState.days !== days || prevState.from !== from || prevState.to !== to || prevState.perPage !== perPage) {
      // this will cause re-fetching all data
      this.setState({ data: new Map() }); // BAD
    }
    if (prevState.data !== data && data.size === 0) {
      this.fetchPage();
    }
  }

  /**
   * Вызывается при первичном появлении компонента в приложении.
   * Инициирует первичный API-запрос на получение данных.
   */
  async componentDidMount() { // overriding default impl
    const { id } = this.props;
    const r206 = await API.req(API.message(id, 206))
    const cycles = r206[0] && r206[0].data ? r206[0].data['Regen-Settings']['Regen Cycles'] : []
    let f = 0
    if (Array.isArray(cycles)) {
      const c = cycles.find(elem => elem.Cycle === 'Fill')
      if (c) {
        console.log(c)
        f = c.Time
      }
    } 
    this.setState({ fill: f })
    this.fetchPage();
  }

  /**
   * Основная отрисовывающая функция компонента.
   * Извлекает из свойств и состояния необходимые данные для отрисовки,
   * и передает их в нижележащий ApexCharts
   */
  render() {
    const {
      page, perPage, totalCount, days, from, to, data, series1, series2, fill
    } = this.state;
    const stat = data.get(page) || [];
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h6">
            Статистика
          </Typography>
          <Grid container>
            {
            [[7, 'За неделю'], [31, 'За месяц'], [90, 'За три месяца'], ['', 'За интервал']]
              .map(item => (
                <Grid
                  item
                  xs={6}
                  sm={3}
                  key={item[0]}
                >
                  <FormControlLabel
                    checked={item[0] === days}
                    value={item[1]}
                    label={item[1]}
                    control={<Radio />}
                    onClick={() => {
                      const newDays = item[0];
                      const [newFrom, newTo] = newDays !== '' ? ['', '']
                        : [days, 0].map(d => new Date(Date.now() - d * 24 * 3600 * 1000).toISOString().split('T')[0]);
                      this.setState({ days: item[0], from: newFrom, to: newTo });
                    }}
                  />
                </Grid>
              ))
          }
          </Grid>
          {
            ['from', 'to'].map(item => (
              <TextField
                label={item}
                type="date"
                value={item === 'from' ? from : to}
                InputLabelProps={{ shrink: true }}
                onChange={event => this.setState({ days: '', [item]: event.target.value })}
                key={item}
              />
            ))
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
              Расход воды, м<sup>3</sup>
          </Typography>
          <DeviceDayChart data={series1} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
              Расход реагента, кг
          </Typography>
          <DeviceDayChart data={series2} />
        </Grid>
        <Grid item xs={12}>
          <Table align="center">
            <TableHead>
              <TableRow>
                <TableCell align="center">Дата</TableCell>
                <TableCell align="center">Расход воды</TableCell>
                <TableCell align="center">Расход реагента</TableCell>
                <Hidden xsDown>
                  <TableCell align="center">Максимальный расход воды</TableCell>
                  <TableCell align="center">Количество регенераций</TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                stat.map(item => (
                  <TableRow key={item.date}>
                    <TableCell align="center">{item.date}</TableCell>
                    <TableCell align="center">{item.usage === null ? 'нет данных' : item.usage / 1000.0}</TableCell>
                    <TableCell align="center">{fill * item.regens}</TableCell>
                    <Hidden xsDown>
                      <TableCell align="center">{item.max_flow || 'нет данных'}</TableCell>
                      <TableCell align="center">{item.regens}</TableCell>
                    </Hidden>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  rowsPerPageOptions={[15, 25]}
                  rowsPerPage={perPage}
                  count={totalCount}
                  onChangePage={(e, newPage) => this.setState({ page: newPage })}
                  onChangeRowsPerPage={e => this.setState({ perPage: e.target.value })}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(DeviceStatsInner);

// vim: ts=2 sw=2 et :
