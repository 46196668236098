import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MyPaper from './MyPaper';
import MacListTable from './MacListTable';
import { API } from './AjaxComponent';


const styles = theme => ({
  tabsRoot: {
    backgroundColor: theme.palette.primary.main,
  },
  tabsSecond: {
    backgroundColor: theme.palette.primary.lightest,
    color: '#fff',
  },
});

/**
 * Список клапанов (дилерская часть).
 */
class MacList extends React.Component {
  state = {
    own: false,
    devices: [],
    holders: [],
    assemblies: [],
    term: '',
    totalCount: 1,
    page: 0,
    pages: 1,
    perPage: 15,
  };

  /**
   * Вызывается при первичном появлении компонента в приложении.
   * Инициирует API-запрос на получение данных.
   */
  async componentDidMount() {
    await this.initFeedData();
  }

  async initFeedData() {
    const promise = this.feedData();
    // these two need only by dialogs (TODO: make search)
    const holders = await API.reqAll(API.holder());
    Array.isArray(holders) && this.setState({ holders });
    const assemblies = await API.reqAll(API.assembly());
    Array.isArray(assemblies) && this.setState({ assemblies });
    await promise;
  }

  handleChange = (event, value) => {
    // this.setState({ own: value === 1 });
    this.feedData(false, false, false, value === 1);
  };

  handleTermChange(term) {
    if (term.length === 0 || term.length > 2) {
      this.feedData(term);
    } else {
      this.setState({ term })
    }
  }

  /**
   * Функция извлечения данных.
   *
   * Считывает из API полный перечень устройств, собственников и изделий
   * при необходимости (разбивка на несколько страниц) делая
   * дополнительные запросы.
   */
  async feedData(term = false, page = false, perPage = false, own = null) {
    if (page === false) page = this.state.page;
    if (perPage === false) perPage = this.state.perPage;
    if (term === false) term = this.state.term;
    if (own === null) own = this.state.own;
    this.setState({perPage, term, own}); // part of state that can be changed immediately
    const r = await API.request(API.device('', term, page + 1, perPage, own));
    // API.rateLimitParse(r.headers);
    const [total, newPage, pages] = API.paginationParse(r.headers);
    this.setState({totalCount: total, page: newPage - 1, pages});
    const devices = await r.json();
    Array.isArray(devices) && this.setState({ devices });
  }

  /**
   * Основная отрисовывающая функция компонента.
   */
  render() {
    const { classes, onGoToDevice, ...other } = this.props;
    const {
      own, devices,
      holders, assemblies,
      term, totalCount, page, perPage
    } = this.state;
    const MyTab = withStyles(theme => ({
      selected: {
        backgroundColor: theme.palette.primary.main,
      },
    }))(Tab);

    return (
      <MyPaper {...other} className="mac-list paper">
        <Typography variant="h6" className="mac-list__header"> Список MAC - адресов </Typography>
        <div className="mac-list__tabs">
          <Tabs
            value={own ? 1 : 0}
            onChange={this.handleChange}
            classes={{ root: classes.tabsSecond }}
          >
            <MyTab label="Все" />
            <MyTab label="Свободные" />
          </Tabs>
        </div>
          <MacListTable
            devices={devices}
            holders={holders}
            assemblies={assemblies}
            onGoToDevice={onGoToDevice}
            refresh={() => this.feedData()}
            term={term}
            page={page}
            perPage={perPage}
            totalCount={totalCount}
            onTermChange={(value) => this.handleTermChange(value)}
            onChangePage={(e, newPage) => this.feedData(term, newPage, false)}
            onChangeRowsPerPage={e => this.feedData(term, false, e.target.value)}
          />
      </MyPaper>
    );
  }
}

MacList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onGoToDevice: PropTypes.func,
};

export default withStyles(styles)(MacList);

// # vim: ts=2 sw=2 et :
