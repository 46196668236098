import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  label: {
    height: theme.spacing(4.5),
    lineHeight: '36px',
    display: 'block',
    marginTop: theme.spacing(3.75),
    // paddingLeft: theme.spacing.unit * 5.25, // old one
    paddingLeft: theme.spacing(2.5),
    fontSize: '14px',
    paddingRight: '15%',
    '&$labelWithIcon': {
      paddingLeft: 0,
    },
  },
  labelIcon: {
    verticalAlign: 'middle',
    marginTop: '5px',
    display: 'inline-block',
  },
  labelWithIcon: {},
  textField: {
    height: theme.spacing(4.5),
    width: '120px',
    float: 'right',
  },
  cssFocused: {},
  cssOutlinedInput: {
    height: theme.spacing(4.5),
    paddingTop: 0,
    paddingBottom: 0,
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.primary.lightest,
    },
    '&$cssOutlinedTextarea': {
      height: 'auto',
    },
  },
  labelCaption: {
    marginLeft: theme.spacing(2.5),
  },
  notchedOutline: {},
  cssOutlinedTextarea: {
  },
  textInput: {
    paddingTop: theme.spacing(1.125),
    paddingBottom: theme.spacing(1.125),
    fontSize: '14px',
    textAlign: 'center',
    color: theme.palette.primary.lightest,
  },
});

/**
 * Поле для задания пользователем одной из настроек клапана.
 *
 * Инкапсулирует TextField, позовляющее вводить текстовые и числовые данне,
 * а также combobox with options.
 */
class ConfigField extends React.Component {
  /** Состояние React-компонента. */
  state = { userValue: null };

  /** Основная функция отрисовки компонента. */
  render() {
    const {
      type, initialValue, classes, userChange, label, labelObj, icon, id, children, ...other
    } = this.props;
    const { userValue } = this.state;
    return (
      <label className={`${classes.label} ${icon ? classes.labelWithIcon : ''}`} htmlFor={id}>
        {icon && <span className={classes.labelIcon}>{icon}</span>}
        <span className={icon ? classes.labelCaption : ''}>{label || labelObj}</span>
        <TextField
          type={type}
          value={userValue !== null ? userValue : initialValue}
          onChange={(event) => {
            userChange(event.target.value);
            this.setState({ userValue: event.target.value });
          }}
          id={id}
          variant="outlined"
          className={classes.textField}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
              input: classes.textInput,
              multiline: classes.cssOutlinedTextarea,
            },
          }}
          {...other}
        >
          {children}
        </TextField>
      </label>
    );
  }
}

ConfigField.propTypes = {
  type: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  userChange: PropTypes.func,
  label: PropTypes.string,
  labelObj: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  icon: PropTypes.node,
  classes: PropTypes.shape({}).isRequired,
};

ConfigField.defaultProps = {
  type: 'text',
  initialValue: '',
  icon: '',
  label: '',
  labelObj: <></>,
  userChange: () => {},
};

export default withStyles(styles)(ConfigField);
