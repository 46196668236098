import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import AjaxComponent from './AjaxComponent';

import WarningItem, { errors } from './WarningItem';

const styles = () => ({});

/**
 * Блок информации с ошибками клапанов.
 */
class WarningBox extends AjaxComponent {
  urls = {};

  devices = [];

  /**
   * Обработчик извлеченных данных.
   */
  dataFetched(id, res) {
    if (id === 'devs') {
      const { deviceId } = this.props;
      const devices = Array.isArray(res) ? res : [res];
      devices.forEach((device) => {
        const urlid = `${device.id}`;
        this.devices.push(urlid);
        this.urls[`100-${urlid}`] = AjaxComponent.message(device.id, 100, '', '', '', 1, 1);
        this.urls[`300-${urlid}`] = AjaxComponent.message(device.id, 300, '', '', '', 1, 1);
      });
      delete this.urls.devs;
      this.fetch();
    }
    super.dataFetched(id, res);
  }

  /**
   * Вызывается при первичном появлении компонента в приложении.
   * Инициирует первичный API-запрос на получение данных, а также
   * таймер на последующее обновление.
   */
  async componentDidMount() {
    this.urls = {
        devs: AjaxComponent.device(this.props.deviceId || ''),
    }
    super.componentDidMount();
    this.timer = setInterval(() => this.fetch(), 15000);
  }

  /**
   * Функция деинициализации компонента
   * (вызывается при удалении со страницы).
   *
   * Останавливает таймеры опросов данных.
   */
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * Конвертирует JSON с информацией об ошибках клапана
   * в набор {@link WarningItem}.
   */
  produce(name) {
    const N = 60; // offline lag
    const M = 30; // lag between last 100 and 300 error to persist
    const errs = [];
    const c100 = this.state[`100-${name}`];
    const c300 = this.state[`300-${name}`];
    if (Array.isArray(c100) && c100.length) {
      const c100top = c100[0];
      if (Array.isArray(c300) && c300.length) { // we have an error
        const c300top = c300[0];
        const error = c300top.data;
        const secs = [c100top, c300top].map(item => Math.floor((new Date(`${item.time}:00`)).getTime() / 1000));
        if (error.command === 300) {
          if (secs[0] - secs[1] < M) {
            if (error['Error Number'] !== 0) {
              if (errors[error['Error Number']]) errs.push({ id: `${error['Error Number']}`, name });
              else errs.push({ text: JSON.stringify(error), name });
            } else {
              // if (error['Low Salt'] === true) errs.push({ id: 'add', name });
              if (error['Low Battery'] === true) errs.push({ id: 'bat', name });
              if (error['Service Alarm Time'] === true) errs.push({ id: 'serv', name });
              if (error['Service Alarm Volume'] === true) errs.push({ id: 'serv', name });
            }
          }
        } else { // unknown error type
          errs.push({ text: JSON.stringify(error), name });
        }
      }
      if (c100top.lag > N) { // check for online
        errs.push({ id: 'off', name });
      }
    } else {
      errs.push({ id: 'off', name });
    }
    return errs;
    // return errs.map(item => <WarningItem ...item />;
  }

  /**
   * Основная отрисовывающая функция компонента.
   */
  render() {
    const errs = this.devices
      .map(item => this.produce(item))
      .reduce((acc, val) => acc.concat(val), []);
    return (
      <Table padding="default">
        <TableBody>
          {
          }
          {
            this.props.demo
              ? ['off', 'bat', 'serv', 'time', 'mav', 'add', 'flow', 'unk']
                .map(item => <WarningItem id={item} name="Изделие-1 | Фильтр 1" key={item} error={{ json: 'some json' }} />)
              : errs.map((item, index) => <WarningItem key={index} {...item} />)
          }
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(WarningBox);

// vim: ts=2 sw=2 et :
