import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ModalFormField from './ModalFormField';


const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
  button: {
    margin: 0,
    fontSize: '13px',
    background: theme.palette.primary.lightest,
    '&:hover': {
      background: theme.palette.primary.azure,
    },
    color: theme.palette.primary.contrastText,
  },
  buttonFont: {
    fontSize: '13px',
  },
  buttonBack: {
    margin: 0,
    position: 'absolute',
    left: 0,
    fontSize: '13px',
  },
});


class ModalDialog extends React.Component {
    state = {
      open: true,
      currentStep: this.props.dialogsInfo.first,
    }

    data = {}

    setStep = (step) => {
      this.setState({ currentStep: step });
    }

    async handleButtonClick(info, button) {
      const nextStep = button.onClick ? await button.onClick(this, info, button) : false;
      if (nextStep) this.setStep(nextStep);
      else this.props.handleClose();
    }

    render() {
      const {
        classes, dialogsInfo, handleClose, selectValues,
      } = this.props;
      const { open, currentStep } = this.state;
      const info = dialogsInfo[currentStep];
      const titleId = `alert-dialog-title-${info.name}`;

      return (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby={titleId}
            className="modal-custom"
            scroll="body"
          >
            <div className="modal-custom__block">
              <div className="modal-custom__inner">
                <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                { info.render ? info.render(this) : (
                  <DialogTitle id={titleId}>{info.title}</DialogTitle>
                )}
                <div>
                  {info.subheader && (<p><strong>{info.subheader}</strong></p>)}
                  {(info.fields || []).map(item => (
                    <ModalFormField
                      item={item}
                      key={item.name}
                      selectValues={selectValues}
                    />
                  ))}
                  { info.actionsVertical ? (
                    <DialogActions className="modal-custom__actions modal-custom__actions_vertical">
                      {info.buttonContained && (
                        <Button
                          onClick={() => this.handleButtonClick(info, info.buttonContained)}
                          variant="contained"
                          size="large"
                          classes={{
                            root: classes.button,
                          }}
                        >
                          { info.buttonContained.title }
                        </Button>
                      )}
                      {info.buttonOutlined && (
                        <Button
                          onClick={() => this.handleButtonClick(info, info.buttonOutlined)}
                          variant="outlined"
                          size="large"
                          className={classes.buttonFont}
                        >
                          { info.buttonOutlined.title }
                        </Button>
                      )}
                    </DialogActions>
                  ) : (
                    <DialogActions className="modal-custom__actions">

                      { info.buttonOutlined && (
                        <Button
                          onClick={() => this.handleButtonClick(info, info.buttonOutlined)}
                          variant="outlined"
                          size="large"
                          className={classes.buttonBack}
                        >
                          { info.buttonOutlined.title }
                        </Button>
                      )
                      }
                      {info.buttonContained && (
                        <Button
                          onClick={() => this.handleButtonClick(info, info.buttonContained)}
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                        >
                          { info.buttonContained.title }
                        </Button>
                      )
                            }
                    </DialogActions>
                  )}
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      );
    }
}

ModalDialog.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  dialogsInfo: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  selectValues: PropTypes.shape({}),
};

ModalDialog.defaultProps = {
  selectValues: {},
};

export default withStyles(styles)(ModalDialog);
// vim: ts=2 sw=2 et :
