import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ConfigField from './ConfigField';
import tr from './i18n';

const styles = (theme) => ({
  label: {
    height: theme.spacing.unit * 4.5,
    lineHeight: '36px',
    display: 'block',
    marginTop: theme.spacing.unit * 3.75,
    paddingLeft: theme.spacing.unit * 5.25,
    fontSize: '14px',
    paddingRight: '15%',
    '&$labelWithIcon': {
      paddingLeft: 0,
    },
  },
  labelIcon: {
    verticalAlign: 'middle',
    marginTop: '5px',
    display: 'inline-block',
  },
  labelWithIcon: {},
  textField: {
    height: theme.spacing.unit * 4.5,
    width: '100px',
    float: 'right',
  },
  cssFocused: {},
  cssOutlinedInput: {
    height: theme.spacing.unit * 4.5,
    paddingTop: 0,
    paddingBottom: 0,
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.primary.lightest,
    },
    '&$cssOutlinedTextarea': {
      height: 'auto',
    },
  },
  labelCaption: {
    marginLeft: theme.spacing.unit * 2.5,
  },
  notchedOutline: {},
  cssOutlinedTextarea: {
  },
  textInput: {
    paddingTop: theme.spacing.unit * 1.125,
    paddingBottom: theme.spacing.unit * 1.125,
    fontSize: '14px',
    textAlign: 'center',
    color: theme.palette.primary.lightest,
  },
});

const allCycles = [
  'Fill',
  'Regenerant Draw Up',
  'Regenerant Draw Down',
  'Backwash',
  'Rinse',
  'End',
];

/**
 * Конфигурационный блок "Настройка режимов промывки".
 *
 * Позволяет задать произвольный набор режимов и их длительность.
 */
class RegenSettings extends React.Component {
  state = { cycles: [] };

  /**
   * Вызывается при первичном появлении компонента в приложении.
   */
  componentDidMount() {
    const { cycles } = this.props;
    console.log('DM', cycles);
    this.setState({ cycles });
  }

  /**
   * Обработчик изменения пользователем режима промывки.
   * @param int index Номер стадии промывки.
   * @param string value Идентификатор режима.
   */
  userUpdateCycle(index, value) {
    const { userChange } = this.props;
    const { cycles } = this.state;
    cycles[index].Cycle = value;
    userChange(cycles);
    this.setState({ cycles });
  }

  /**
   * Обработчик изменения пользователем времени промывки.
   * @param int index Номер стадии промывки.
   * @param int value Время промывки в секундах.
   */
  userUpdateTime(index, value) {
    const { userChange } = this.props;
    const { cycles } = this.state;
    cycles[index].Time = Math.trunc(value); // FIXME: integer?
    userChange(cycles);
    this.setState({ cycles });
  }

  /**
   * Основная отрисовывающая функция компонента.
   */
  render() {
    const { cycles } = this.state;
    return (
      <>
        <Typography
          variant="h6"
          style={{ marginLeft: '32px', marginTop: '16px' }}
        >
Стадии промывки
        </Typography>
        {
        cycles.map((item, index) => (
          <Grid container key={index}>
            <Grid item xs={6}>
              <ConfigField
                id={`cycle-${index}`}
                select
                initialValue={item.Cycle}
                label="Стадия"
                onChange={(event) => this.userUpdateCycle(index, event.target.value)}
              >
                { allCycles.map((option) => (
                  <MenuItem key={option} value={option}>
                    { tr(option, 206) }
                  </MenuItem>
                )) }
              </ConfigField>
            </Grid>
            <Grid item xs={6}>
              <ConfigField
                id={`time-${index}`}
                label="Время"
                type="text"
                value={item.Time}
                onChange={(event) => this.userUpdateTime(index, event.target.value)}
              />
            </Grid>
          </Grid>
        ))
      }
        <Button
          variant="outlined"
          onClick={
                () => {
                  const newCycles = [...cycles, { Cycle: 'End', Time: 0 }];
                  this.props.userChange(cycles);
                  this.setState({ cycles: newCycles });
                }
            }
          style={{ width: '260px', marginLeft: '32px', marginTop: '16px' }}
        >
            Добавить стадию
        </Button>
      </>
    );
  }
}

RegenSettings.propTypes = {
  cycles: PropTypes.array.isRequired,
  userChange: PropTypes.func,
};

export default withStyles(styles)(RegenSettings);
