import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
// import ChevronRight from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import { API } from './AjaxComponent';
import DeviceSettingsTab from './DeviceSettingsTab';
// import DeviceSettingsSystem from './DeviceSettingsSystem';
// import DeviceSettingsAdjuster from './DeviceSettingsAdjuster';

import { UserContext } from './UserContext';

import * as config1m from './config-common';
import * as config2m from './config-installer';
import * as config3m from './config-config';
import * as config4m from './config-settings';

const styles = theme => ({});
// const styles = theme => ({
//   root: {
//   },
//   tabsRoot: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   tabsSecond: {
//     backgroundColor: theme.palette.secondary.main,
//   },
//   tabChevron: {
//     position: 'absolute',
//     right: '12%',
//   },
// });


const config = config1m.default;


const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
const config2 = config2m.default;
const config3 = config3m.default;
const config4 = config4m.default;

/**
 * Вкладки "Настройка" клапана.
 *
 * Содержит внутренние вкладки.
 * Набор внутренних вкладок определяется в зависимости от статуса текущего
 * пользователя.
 */
class DeviceSettings extends React.Component {
  static contextType = UserContext;

  /**
   * Состояние React-компонента.
   * @prop {number} value Номер текущей вкладки.
   * @prop {bool} updating Флаг "обновление в процессе".
   */
  state = { value: 0, updating: false };

  /**
   * Переменная, в которой компонуются команды для изменения конфигурации
   * клапана. По мере того, как пользователь изменяет данные в полях формы,
   * происходит заполнение данного объекта. Функции, трансформирующие
   * введенное пользователем значение в компонент MQTT-команды клапана,
   * содержатся в конфигурационных массивах.
   */
  cmds = {};

  /**
   * Обработчик изменения активной вкладки.
   * @param {Event} event Событие JavaScript
   * @param {number} value Номер активированной вкладки.
   */
  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  /**
   * Извлечение обновлённых настроек клапана.
   *
   * Данная функция вызывается после нажатия кнопки применить,
   * после того, как все измененные настройки отправлены в MQTT-брокер.
   * Опрос делается командой {"command": 200, "section": <cmd>}.
   * @param {(number|string)} cmd Код команды, настройки которой требуется 
   * опросить.
   */
  async fetchParams(cmd) {
    const { id } = this.props;
    console.log('wait', cmd);
    await wait(25000);
    console.log('query', cmd);
    await API.apiMessagePost(id, 200, { section: Math.trunc(+cmd) });
    await wait(1000);
    console.log('read db', cmd);
    const res = await this.fetchData(id, cmd);
    console.log(res, res.lag);
  }

  /**
   * Ожидание команды 301 от клапана в MQTT-брокере, из которой извлекается
   * информация об успешном изменении настроек либо ошибке.
   *
   * Если поле result, возвращаемое в теле 301 команды от клапана, не равно 0,
   * генерируется исключение, которое перехватывается при отладке в
   * development-сборке приложения.
   */
  async wait301() {
    const { id } = this.props;
    const res = await this.fetchData(id, 301);
    console.log(301, res);
    if (res.data.result !== 0) {
      throw new Error(res.data['error reason']);
    }
  }

  /*
   * Обработчик нажатия кнопки "Применить"
   */
  handleApply = async () => {
    const [{ id }, cmds] = [this.props, Object.keys(this.cmds)];
    const promises = Object.keys(this.cmds).map(cmd => this.applyOne(cmd));
  };

  /**
   * Функция инициализации компонента
   * (вызывается при появлении на странице)
   */
  async componentDidMount() {
    const { id } = this.props;
    // await API.queryDevice(id); // TODO: replacing with explicit
    // making array of unique value-getting MQTT messages and sending 'em all
    const msgs = [...new Set(config.concat(config2, config3, config4)
      .filter(item => item.get_payload !== false && item.code >= 200)
      .map(
        item => (item.get_payload
          ? { command: item.code, ...item.get_payload }
          : { command: 200, section: item.code }),
      )
      .map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
    console.log('getobject', msgs);
    await Promise.all(
      msgs.map(msg => API.apiMessagePost(id, msg.command, msg)),
    );
    [...new Set(config.concat(config2, config3, config4).map(item => item.code))]
      .map(item => this.fetchData(id, item));
  }

  async fetchData(id, code) {
    const data = await API.req(API.message(id, code));
    if (Array.isArray(data) && data.length) {
      this.setState({ [code]: data[0].data });
    }
    return new Promise(resolve => resolve(data[0]));
  }

  /**
   * Обработчик изменения пользователем настроек в {@link ConfigField}.
   * Трансформирует {@link DeviceSettings#cmds|cmds}, дополняя его MQTT-сообщением, изменяющим
   * настройку клапана. Трансформирующая функция берется из конфигурационного
   * массива, нужный элемент которого, связанный с изменяемым параметром,
   * подставляется в параметр функции.
   * @param {object} item Элемент конфигурационного массива, соответсвующий
   * изменяемому пользователем полю.
   * @param {string} value Значение, введенное пользователем в поле.
   */
  userChange = (item, value) => {
    if (item.setter) {
      this.cmds[item.code] = Object.assign(this.cmds[item.code] || {}, item.setter(value));
      return true;
    }
    return false;
  }

  /**
   * Применение всех настроек клапана, относящихся к конкретной MQTT-команде.
   * Производит как отправку команды, изменяющей настройки, так и ожидание
   * ответа клапана, опрос измененных настроек, и обновление интерфейса.
   * @param {number} cmd Код команды, изменяющей настройки клапана.
   */
  async applyOne(cmd) {
    this.setState({ updating: true });
    const { id } = this.props;
    console.log('send', cmd, this.cmds[cmd]);
    await API.apiMessagePost(id, cmd, this.cmds[cmd]);
    await this.wait301();
    await this.fetchParams(cmd);
    await this.componentDidMount();
    this.setState({ updating: false });
  }

  /** Основная функция отрисовки компонента. */
  render() {
    const { id, classes } = this.props;
    const { value } = this.state;

    const myTabs = ['Общие', 'Конфигурация', 'Настройка системы', 'Настройки монтажника'].map((item, index) => (
      <Tab
        label={item}
        key={item}
        // icon={value === index
        // && <ChevronRight fontSize="small" className={classes.tabChevron} />}
      />
    ));

    const isDealer = UserContext.isDealer(this.context);

    return (
      <>
        { isDealer && (
        <div
          style={{
            display: 'flex',
          }}
        >
          <Tabs
            value={value}
            onChange={this.handleChangeTab}
            variant="scrollable"
            scrollButtons="on"
          >
            {myTabs}
          </Tabs>
        </div>
        )
          }
        { value === 0 && (
          <DeviceSettingsTab
            id={id}
            config={config}
            fetchData={this.fetchData}
            handleApply={this.handleApply}
            userChange={this.userChange}
            params={this.state}
          />
        ) }
        { isDealer && value === 1 && (
          <DeviceSettingsTab
            id={id}
            config={config3}
            fetchData={this.fetchData}
            handleApply={this.handleApply}
            userChange={this.userChange}
            params={this.state}
          />
        ) }
        { isDealer && value === 2 && (
          <DeviceSettingsTab
            id={id}
            config={config4}
            fetchData={this.fetchData}
            handleApply={this.handleApply}
            userChange={this.userChange}
            params={this.state}
          />
        ) }
        { isDealer && value === 3 && (
          <DeviceSettingsTab
            id={id}
            config={config2}
            fetchData={this.fetchData}
            handleApply={this.handleApply}
            userChange={this.userChange}
            params={this.state}
          />
        ) }
      { /*isDealer &&*/ (
        <Box style={{marginLeft: '32px', marginTop: '16px'}}>
        <p>
        Изменение данных настроек может привести к некорректной работе фильтра и резкому ухудшению качества воды.
        <br />
Пожалуйста, не изменяйте их без консультации со специалистами компании Экодар.
        </p>
        </Box>
      )
      }
        {
      !this.state.updating ? (
        <Button
          id={id}
          onClick={this.handleApply}
          variant="contained"
          color="primary"
          size="large"
          style={{width: '260px', marginLeft: '32px', marginTop: '16px'}}
        >
          Применить
        </Button>
      ) : (
        <span>Идет обновление. Пожалуйста, подождите.</span>
      )
      }
      </>
    );
  }
}


DeviceSettings.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(DeviceSettings);
// vim: ts=2 sw=2 et :
