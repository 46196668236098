import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import MacListDialogs from './MacListDialogs';
import { UserContext } from './UserContext';
import { API } from './AjaxComponent';

const styles = theme => ({
});

class MacListTable extends React.Component {
  static contextType = UserContext;

  state = {
    openTie: false,
    device: false,
    anchorEl: null,
  }

  handleTieOpen(device) {
    this.setState({ openTie: true, device });
  }

  handleTieClose = () => {
    this.setState({ openTie: false });
  }

  canBeBound(object) {
    const user = this.context;
    return user && object && object.holder_id === user.holder_id;
  }

  handleGoClick(device) {
    this.props.onGoToDevice(device);
  }


  handleClick(event, device) {
    this.setState({ device, anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  async handleUnbound(device) {
    const user = this.context;
    await API.req(API.device(device.id), 'PUT', { holder_id: user.holder_id });
    this.handleClose();
    this.props.refresh();
  }

  render() {
    const {
      classes,
      devices = [],
      holders = [],
      assemblies = [],
      refresh,
      term,
      onTermChange,
      onChangePage,
      onChangeRowsPerPage,
      page,
      perPage,
      totalCount
    } = this.props;
    const {
      openTie, device, anchorEl
    } = this.state;
    const open = Boolean(anchorEl);
    const macList = devices;

    return (
      <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {
            this.canBeBound(device) && holders.length > 0 && (
              <MenuItem onClick={() => this.handleTieOpen(device)}>
                Привязать к клиенту
              </MenuItem>
            )
          }
          <MenuItem onClick={() => this.handleGoClick(device)}>
            Перейти к клапану
          </MenuItem>
          {
            !this.canBeBound(device) && (
              <MenuItem onClick={() => this.handleUnbound(device)}>
                Отвязать
              </MenuItem>
            )
          }
        </Menu>
        <TextField
          label="Поиск (мин. 3 символа)"
          onChange={event => onTermChange(event.target.value)}
          value={term}
        />
        <Table align="center" className="mac-list__table">
          <TableHead>
            <TableRow>
              <TableCell align="center">MAC - адрес</TableCell>
              <Hidden xsDown><TableCell align="center">Статус</TableCell></Hidden>
              <TableCell align="center"></TableCell>
              <Hidden xsDown><TableCell align="center">Пользовательское название клапана</TableCell></Hidden>
              <Hidden smDown><TableCell align="center">Привязан к клиенту</TableCell></Hidden>
              <Hidden smDown><TableCell align="center">Входит в состав изделия</TableCell></Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              macList.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="center">{item.id}</TableCell>
                  <Hidden xsDown>
                    <TableCell align="center">{this.canBeBound(item) ? 'Свободен' : 'Привязан'}</TableCell>
                  </Hidden>
                  <TableCell>
                    <Button
                      aria-owns="simple-menu"
                      aria-haspopup="true"
                      onClick={event => this.handleClick(event, item)}
                      variant="outlined"
                    >
                      Действия
                    </Button>
                  </TableCell>
                  <Hidden xsDown>
                    <TableCell align="center">{item.name}</TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="center">
(
                      {item.holder_id}
)
                      {item.holder.name}
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="center">{item.assemblies && item.assemblies.length > 0 && item.assemblies[0].name}</TableCell>
                  </Hidden>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  rowsPerPageOptions={[15, 25]}
                  rowsPerPage={perPage}
                  count={totalCount}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </TableRow>
          </TableFooter>
        </Table>
        {openTie && (
          <MacListDialogs
            device={device}
            devices={devices.filter((d) => this.canBeBound(d))}
            assemblies={assemblies}
            holders={holders}
            handleClose={this.handleTieClose}
            refresh={refresh}
          />
        )}
      </>
    );
  }
}

MacListTable.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  refresh: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withStyles(styles)(MacListTable);

// # vim: ts=2 sw=2 et :
