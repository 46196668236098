import { Component } from 'react';
import debug from './debug'

// const baseurl = 'http://localhost:8080/v1';
const baseurl = `https://api.${window.location.host}/v1`;

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["headersFetched"] }] */
/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "headers" }] */

function idpart(id) {
  return id === '' ? '' : '/' + id;
}

/**
 * Основной класс для инкапсуляции асинхронных запросов к RESTful API,
 * а также формирования URLs для API endpoints.
 *
 * В ранних версиях проекта использовался как базовый класс для всех
 * React-компонентов, которые для отображения содержимого требуют
 * загрузку данных через API.
 *
 * В текущей версии проекта от такого использования применений отказались
 * в пользу вызова статических методов для генерации URL и статических
 * методов асинхронных запросов.
 */
export class API extends Component {
  static token;

  static rc = 0;

  static wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  /**
   * Задание API token
   * @param {string} token API-токен пользователя для доступа к RESTful API
   */
  static setToken(t) {
    API.token = t;
    debug('token set');
  }

  // new URL functions

  /**
   * Формирование URL для запроса через API: устройства.
   * @param {string} id MAC-адрес устройства, по умолчанию - вывод списка
   * @param {string} search Подстрока поиска для ограничения выборки
   * @param {number} page Номер страницы (если результат запроса поделен на страницы).
   * @param {number} perPage Кол-во элементов на странице.
   * @param {number} own Ограничить выбор устройствами, привязанными к текущему пользователю.
   * @return URL запроса к RESTful API
   */
  static device(id = '', search = '', page = 1, perPage = 25, own = false) {
    const queryParams = {
      page,
      'per-page': perPage,
      expand: 'holder,assemblies',
      ...(search ? { search } : {} ),
      ...(own ? { own: 1 } : {})
    };
    return baseurl + '/device' + idpart(id) + '?' + this.serialize(queryParams);
  }

  /**
   * Формирование URL для запроса через API: собственники
   * @param {string} id ID собственника, по умолчанию - вывод списка
   * @param {string} search Подстрока поиска для ограничения выборки
   * @param {number} page Номер страницы (если результат запроса поделен на страницы).
   * @param {number} perPage Кол-во элементов на странице.
   * @return {string} test URL запроса к RESTful API
   */
  static holder(id = '', search = '', page = 1, perPage = 25) {
    const queryParams = { page, 'per-page': perPage, expand: 'users', ...(search ? { search } : {} )};
    return baseurl + '/holder' + idpart(id) + '?' + this.serialize(queryParams);
  }

  /**
   * Формирование URL для запроса через API: пользователи
   * @param {string} id ID пользователя, по умолчанию - вывод списка
   */
  static user(id = '') { // empty string for GET list
    return `${baseurl}/user${idpart(id)}?expand=holder`;
  }

  /**
   * Формирование URL для запроса через API: изделия
   * @param {string} id ID изделия, по умолчанию - вывод списка
   */
  static assembly(id = '') {
    return `${baseurl}/assembly${idpart(id)}?expand=devices,holder`;
  }

  /**
   * Формирование URL для запроса через API: изделия
   * @param {string} id ID изделия, по умолчанию - вывод списка
   */
  static property(holder_id, user_id, device_id, assembly_id, fields = []) {
    return `${baseurl}/property?holder_id=${holder_id}&fields=${fields.join(',')}`;
  }

  /**
   * Формирование URL для запроса через API: сообщения MQTT.
   * @param {string} id MAC-адрес устройства.
   * @param {number} code Поле "command" в MQTT-сообщениях клапанов.
   * @param {date} from Дата/время начала выборки (в ISO-формате).
   * @param {date} to Дата/время окончания выборки (в ISO-формате).
   * @param {number} secs Ограничение "не ранее чем secs секунд" от последней записи.
   * @param {number} page Номер страницы (если результат запроса поделен на страницы).
   * @param {number} perPage Кол-во элементов на странице.
   */
  static message(id, code, from = '', to = '', secs = '', page = 1, perPage = 1) {
    const params = {
      code, from, to, secs, page, 'per-page': perPage, incoming: false, expand: 'lag',
    };
    return `${baseurl}/message/${id}?${this.serialize(params)}`;
  }

  /**
   * Формирование URL для запроса через API: данные статистики
   * @param {string} id MAC-адрес устройства.
   * @param {date} from Дата/время начала выборки (в ISO-формате).
   * @param {date} to Дата/время окончания выборки (в ISO-формате).
   * @param {number} days Ограничение "не ранее чем days дней" от последней записи.
   * @param {number} page Номер страницы (если результат запроса
   * поделен на страницы).
   * @param {number} Кол-во элементов на странице.
   */
  static statistic(id, from, to, days, page, perPage) {
    const params = {
      from, to, days, page, 'per-page': perPage,
    };
    return `${baseurl}/statistic/${id}?${this.serialize(params)}`;
  }

  /**
   * Сериализация JS-объекта в параметры URL c %-кодировкой.
   * @param {object} obj Объект для сериализации.
   */
  static serialize(obj) {
    return Object.keys(obj).map(k => (
      `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`
    )).join('&');
  }

  /**
   * Устаревшие функции формирования URL (для обратной совместимости)
   * имеют вид api___Get()
   */
  static apiDeviceGet(id) {
    return API.device(id);
  }

  static apiHolderGet(id = '') { // empty string for GET list
    return API.holder(id);
  }

  static apiUserGet() {
    return API.user('i');
  }

  static apiUserLogin() {
    return `${baseurl}/user/login`;
  }

  static apiAssemblyGet(id = '') {
    return API.assembly(id);
  }

  static apiMessageGet(id, code, perPage) {
    return API.message(id, code, '', '', '', 1, perPage);
  }

  static apiMessageGet2(id, code, from = '', to = '', secs = '', page = 1, perPage = 1) {
    return API.message(id, code, from, to, secs, page, perPage);
  }

  static apiStatisticGet(id, from, to, days, page, perPage) {
    return API.statistic(id, from, to, days, page, perPage);
  }

  // Returns promise
  /**
   * Произвольный запрос к RESTful API
   * @param {string} url URL запроса
   * @param {string} method Метод запроса
   * @param {object} body Объект с параметрами запроса
   */
  static request(url, method = 'GET', body = undefined) {
    return fetch(url, {
      method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': API.token,
      },
      body: body ? JSON.stringify(body) : body,
    });
  }

  // Waits for response and returns promise to json
  /**
   * Асинхронный произвольный запрос к RESTful API,
   * автоматическая декодировка результата в JSON.
   * @param {string} url URL запроса
   * @param {string} method Метод запроса
   * @param {object} body Объект с параметрами запроса
   * @return {Promise} Promise с ответом сервера (JSON) при resolve
   */
  static async req(url, method = 'GET', body = undefined) {
    try {
      const r = await API.request(url, method, body);
      return r.json();
    } catch (e) {
      debug(e);
    }
  }

  // Waits for response and returns promise to json
  /**
   * Асинхронный запрос 1-й страницы, с парсингом данных в
   * HTTP-заголовках о разбиении данных на страницы,
   * и последующим автоматическим извлечением остальных страниц.
   *
   * Данные в страничных массивах автоматически соединяются в
   * единый массив.
   *
   * @param {string} url URL запроса
   * @param {string} method Метод запроса
   * @param {object} body Объект с параметрами запроса
   * @return {Promise} Promise с ответом сервера (JSON) при resolve
   */
  static async reqAll(url, method = 'GET', body = undefined) {
    try {
      const r = await API.request(url, method, body);
      const p = API.paginationParse(r.headers);
      const j = await r.json();
      let result = j;
      for (let i = 2; i <= p[2]; i += 1) {
        const r2 = await API.request(url + '&page=' + i, method, body);
        const j2 = await r2.json();
        result = result.concat(j2);
      }
      return new Promise((resolve) => { resolve(result); });
    } catch (e) {
      return new Promise((resolve, reject) => { reject(new Error(e)); });
    }
  }

  static async tryReqByCode(tries, delay, id, code, from = '') {
    for (let t = 0; t < tries; t += 1) {
      debug('try', t, code, from);
      /* eslint-disable no-await-in-loop */
      const data = await API.req(API.message(id, code, from));
      if (!Array.isArray(data)) {
        debug('Fetch', code, 'not array returned', data);
      }
      if (data.length > 0) return data[0];
      await API.wait(delay);
    }
    debug('Fetch', code, 'tries exceeded');
    return null;
  }


  static async apiMessagePost(id, code, payload = {}) {
    const url = `${baseurl}/message/${id}`;
    const r = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': API.token,
      },
      body: JSON.stringify({ code, data: JSON.stringify(payload) }),
    });
    return r.json();
  }

  static async queryDevice(id, sections = [201, 202, 204, 208]) {
    return Promise.all(sections.map(
      item => API.apiMessagePost(id, 200, { section: item }),
    ));
  }

  /**
   * Извлечение из HTTP-заголовков данных о страничной разбивке
   * @param {object} headers Объект с полями HTTP-заголовков
   * @return {array} Общее кол-во элементов, текущая страница, всего страниц
   */
  static paginationParse(headers) {
    return ['x-pagination-total-count', 'x-pagination-current-page', 'x-pagination-page-count'].map(item => Math.trunc(headers.get(item)));
  }

  /**
   * Метод, отвечающий за проверку на статус дилера.
   * Во всех местах кода, где требуется подобная проверка, следует
   * использовать именно этот метод на случай изменения модели данных
   * или логики проверки.
   */
  static isHolderDealer(holder) {
    return holder.status === 1;
  }

  state = {};

  /**
   * Обработчик создания компонента.
   * извлекает API-ключ из localStorage и опраишвает API
   * о текущем пользователе
   */
  componentDidMount() {
    this.fetch();
  }

  /**
   * Обработчик поступивших данных из API.
   * @param {string} id Ключ массива this.urls.
   * @param {object} Загруженные данные в виде JSON.
   */
  dataFetched(id, res) {
    this.setState({ [id]: res });
  }

  /**
   * Обработчик поступивших HTTP-заголовков из ответа на запрос API.
   * @param {object} headers HTTP-заголовки.
   */
  headersFetched(headers) {}

  responseProcess(res) {
    this.headersFetched(res.headers);
    return res.json();
  }

  errorGot(error) {
    debug(error);
    this.setState({ error });
  }

  fetchOne(id, url) {
    API.rc += 1;
    fetch(url, {
      mode: 'cors',
      headers: {
        'X-API-Key': API.token,
      },
    })
      .then(res => this.responseProcess(res))
      .then(
        (res) => {
          this.dataFetched(id, res);
        },
        (error) => {
          this.errorGot(error);
        },
      );
  }

  static fetch2(id, url, handleData, handleHeaders = null, handleError = null) {
    API.rc += 1;
    fetch(url, {
      mode: 'cors',
      headers: {
        'X-API-Key': API.token,
      },
    }).then(
      (res) => {
        if (res.status !== 200) {
          handleError({ err: res.status });
        }
        if (handleHeaders) handleHeaders(res.headers);
        return res.json();
      },
    ).then(
      (res) => {
        handleData(id, res);
      },
      (error) => {
        handleError && handleError(error);
      },
    );
  }

  fetch() {
    const urls = this.urls || [];
    Object.keys(urls).forEach(id => this.fetchOne(id, urls[id]));
  }
}

export default API;

// vim: ts=2 sw=2 et :
