import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({});
const sp = 3.75;
// obsolete styles
const styles2 = theme => ({
  root: {
    backgroundColor: '#d3dee8',
  },
  paperRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(sp),
    paddingBottom: theme.spacing(sp),
    maxWidth: 1366,
    marginTop: 0,
    marginBottom: theme.spacing(sp),
    marginLeft: 'auto',
    marginRight: 'auto',
    '&$sidePadding': {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
});

const MyPaper2 = (props) => {
  const { classes, children, ...other } = props;
  return (
    <Paper
      classes={{
        root: classes.paperRoot,
        rounded: classes.sidePadding,
      }}
      elevation={1}
      {...other}
    >
      {children}
    </Paper>
  );
};

const MyPaper3 = Paper;
const MyPaper = props => (
  <Grid container>
    <Grid item xs={12}>
      <Paper>
        {props.children}
      </Paper>
    </Grid>
  </Grid>
);

export default MyPaper;
// export default withStyles(styles2)(MyPaper2);
