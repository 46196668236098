import { domain } from './Domains';

const hardness = {
    label: 'Жесткость',
    type: 'text',
    code: 201,
    getter: data => (data['Installer-Settings']['Water Hardness'] / 10.0).toFixed(1),
    setter: value => ({ 'Water Hardness': Math.trunc(value * 10) }),
    id: 'water-hardness',
}

const regen_days = {
    label: 'Дней между промывками',
    type: 'text',
    code: 201,
    getter: data => data['Installer-Settings']['Day Override'],
    setter: value => ({ 'Day Override': Math.trunc(value) }),
    id: 'day-override',
}

const config = [
  {
    label: 'Перезагрузка',
    type: 'button',
    code: 113,
    get_payload: false,
    payload: { level: 0 },
    id: 'reboot',
  },
  {
    label: 'Время клапана',
    type: 'time',
    code: 208,
    getter: data => ['Valve Hour', 'Valve Minute'].map(i => data['Time-Settings'][i].toString().padStart(2, '0')).join(':'),
    setter: (value) => {
      const [h, m] = value.split(':').map(i => Math.trunc(i));
      return { 'Valve Hour': h, 'Valve Minute': m };
    },
    id: 'valve-time',
  },
  {
    label: 'Время промывки',
    type: 'time',
    code: 201,
    getter: data => ['Regen Time-Hour', 'Regen Time-Minute'].map(i => data['Installer-Settings'][i].toString().padStart(2,'0')).join(':'),
    setter: (value) => {
      const [h, m] = value.split(':').map(v => Math.trunc(v));
      return { 'Regen Time-Hour': h, 'Regen Time-Minute': m };
    },
    id: 'regen-time',
  }].concat(domain() === 1 ? [
  {
    label: 'День недели',
    type: 'select',
    options: [
      { value: 'Monday' },
      { value: 'Tuesday' },
      { value: 'Wednesday' },
      { value: 'Thursday' },
      { value: 'Friday' },
      { value: 'Saturday' },
      { value: 'Sunday' },
    ],
    code: 208,
    getter: data => data['Time-Settings']['Valve Day'],
    setter: value => ({ 'Valve Day': value }),
    id: 'valve-day',
  },
  ] : [], domain() === 1 ? [ regen_days, hardness ] : [ hardness, regen_days ]
  // {
  //   label: 'Остаточная жесткость',
  //   type: 'text',
  //   code: 201,
  //   getter: data => (data['Installer-Settings']['Service Hardness'] / 10.0).toFixed(1),
  //   setter: value => ({ 'Service Hardness': Math.trunc(value * 10.0) }),
  //   id: 'service-hardness',
  // },
);

export default config;
