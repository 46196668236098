import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MyPaper from './MyPaper';
// import styles from './Styles';

const styles = theme => ({})

/**
 * Клиентский блок с краткой информацией по клиенту и полем для комментария.
 */
class Client extends React.Component {
  state = { };

  /** Основная функция отрисовки компонента. */
  render() {
    const { classes, holder, onClick } = this.props;

    return (
      <MyPaper className={classes.paper}>
        <img src="/img/default-client-logo.jpg" alt="Клиент" className={classes.clients__photo} />
        <div className={classes.clients__personal}>
          <div className={classes.clients__type}>Клиент</div>
          <div className={classes.clients__name}>{ holder.name }</div>
        </div>
        <Button
          className={classes.clients__btnAll}
          variant="outlined"
          onClick={() => onClick()}
        >
          Все клиенты
        </Button>
        <TextField
          label="Ваши комментарии"
          margin="normal"
          fullWidth
          multiline
          variant="outlined"
          rows={3}
          type="text"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
              input: classes.textInput,
              multiline: classes.cssOutlinedTextarea,
            },
          }}
          className={classes.textarea}
        />
        <Button className={`${classes.button} ${classes.clients__btnSave}`} variant="contained" size="large" color="primary">
          Сохранить
        </Button>
      </MyPaper>
    );
  }
}

Client.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  holder: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Client);
// vim: ts=2 sw=2 et :
