import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import DeviceInfo from './DeviceInfo';
import DeviceStats from './DeviceStats';
import DeviceRegen from './DeviceRegen';
import DeviceSettings from './DeviceSettings';
import DeviceArchive from './DeviceArchive';
import WarningBox from './WarningBox';

import { UserContext } from './UserContext';

const styles = () => ({});

/**
 * Содержимое вкладки клапана.
 * Содержит вкладки "Информация", "Статистика" и т.д.
 */
class OneDeviceTabs extends React.Component {
  static contextType = UserContext;

  /**
   * Состояние React-компонента.
   */
  state = { value: 0 };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  /** Основная функция отрисовки компонента. */
  render() {
    const { id } = this.props; // FIXME pass all props to WarningBox
    const { value } = this.state;
    const user = this.context;
    return (
      <div>
        <Tabs value={value} onChange={this.handleChange} variant="scrollable" scrollButtons="on">
          <Tab label="Информация" />
          <Tab label="Статистика" />
          <Tab label="Промывка" />
          <Tab label="Настройка" />
          {UserContext.isDealer(user) && <Tab label="Архив" disabled />}
          {UserContext.isDealer(user) && <Tab label="Предупреждения" />}
        </Tabs>
        <div style={{ padding: '30px 0 10px' }}>
          {value === 0 && <DeviceInfo {...this.props} />}
          {value === 1 && <DeviceStats {...this.props} />}
          {value === 2 && <DeviceRegen {...this.props} />}
          {value === 3 && <DeviceSettings {...this.props} />}
          {value === 4 && <DeviceArchive {...this.props} />}
          {value === 5 && (
          <>
            <Typography variant="h6">Предупреждения</Typography>
            <WarningBox id={id} deviceId={UserContext.isDealer(user) && id} />
          </>
          )}
        </div>
      </div>
    );
  }
}

OneDeviceTabs.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(OneDeviceTabs);

// vim: ts=2 sw=2 et :
