import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Chart from 'react-apexcharts';

const boptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      barHeight: '100%',
      distributed: true,
      horizontal: true,
      dataLabels: {
        position: 'bottom',
      },
    },
  },
  theme: {
    palette: 'palette10',
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#fff'],
    },
    formatter(val, opt) {
      return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val}`;
    },
    offsetX: 0,
    dropShadow: {
      enabled: true,
    },
  },

  // stroke: {
  //   width: 1,
  //   colors: ['#fff'],
  // },
  yaxis: {
    labels: {
      show: false,
    },
    min: 0,
    max: 100,
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  //   theme: 'dark',
  //   x: {
  //     show: false,
  //   },
    // y: {
    //   title: {
    //     formatter() {
    //       return '';
    //     },
    //   },
    // },
  },
};

const styles = () => ({});

/**
 * Конфигурационный массив переводов названий режимов.
 */
export const cycleTr = {
  Fill: 'Заполнение бака',
  Backwash: 'Обратная промывка',
  'Regenerant Draw Down': 'Регенерация сверху-вниз',
  'Regenerant Draw Up': 'Регенерация снизу-вверх',
  Rinse: 'Прямая промывка',
  End: 'Завершение',
  'Softening/Service': 'Умягчение/работа',
};

/**
 * График статуса промывки клапана со стадиями.
 *
 * Конфигурацию компонент считывает из API через Props из
 * внешнего компонента.
 */
class RegenChart extends React.PureComponent {
  /**
   * Основная отрисовывающая функция компонента.
   */
  render() {
    const { cycles, status, cycleCodes } = this.props;

    let currentIndex = 0;
    let currentPercent = 0;
    let currentRepeat = 1;
    const [repeat, cycle] = [status['repeat-num'], cycleCodes[status['current-cycle']]];
    cycles.forEach((item, index) => {
      if (item.Cycle === cycle) {
        if (repeat === 0 || repeat === currentRepeat) {
          currentIndex = index;
          currentPercent = 100 - Math.floor(100 * status['cycle-time'] / (item.Time * 60));
          // console.log('got', repeat, cycle, '>', currentIndex, currentPercent, currentRepeat);
        }
          currentRepeat += 1;
      }
    });
    const options = {
      ...boptions,
      xaxis: {
        labels: { show: false },
        categories: cycles.map(item => cycleTr[item.Cycle]),
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
    };
    const series = [{ data: cycles.map((item, index) => (index < currentIndex ? 100 : (index > currentIndex ? 0 : currentPercent))) }];
    // console.log(options.xaxis.categories);
    // console.log(series[0].data);
    return (
      <Chart type="bar" options={options} series={series} />
    );
  }
}

export default withStyles(styles)(RegenChart);

// vim: ts=2 sw=2 et :
