import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import BuildIcon from '@material-ui/icons/Build';
import WavesIcon from '@material-ui/icons/Waves';
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';

const styles = () => ({});

/**
 * Конфигурационный массив описаний ошибок и сопровождающих пиктограмм
 */
export const errors = {
  off: {
    icon: <PortableWifiOffIcon />,
    text: 'Клапан оффлайн. Проверьте соединение клапана с Интернет.',
  },
  bat: {
    icon: <BatteryAlertIcon />,
    text: 'Требуется замена элементов питания.',
  },
  serv: {
    icon: <BuildIcon />,
    text: 'Необходимо сервисное обслуживание.',
  },
  flow: {
    icon: <WavesIcon style={{ color: '#00f' }} />,
    text: 'Превышение допустимого расхода воды.',
  },
  add: {
    icon: <AddCircleOutlineIcon color="primary" />,
    text: 'Добавьте реагент',
  },
  time: {
    icon: <TimerOffIcon style={{ color: '#ff8000' }} />,
    text: 'Расхождение по текущему времени',
  },
  mav: {
    icon: <WarningIcon color="error" />,
    text: 'Мотор редуктора внешнего клапана вращался слишком мало времени и застопорился. Обратитесь в сервисный центр',
  },
  101: {
    icon: <WarningIcon color="error" />,
    text: 'Ошибка запуска',
  },
  102: {
    icon: <WarningIcon color="error" />,
    text: 'Непредвиденная остановка двигателя клапана',
  },
  103: {
    icon: <WarningIcon color="error" />,
    text: 'Двигатель клапана работает слишком долго для определения положения поршня',
  },
  104: {
    icon: <WarningIcon color="error" />,
    text: 'Неправильное положение поршня клапана',
  },
  106: {
    icon: <WarningIcon color="error" />,
    text: 'Двигатель внешнего клапана 1 работает слишком долго, а клапан не определяет, что он находится в нужном положении',
  },
  107: {
    icon: <WarningIcon color="error" />,
    text: 'Остановка двигателя внешнего клапана 1',
  },
  116: {
    icon: <WarningIcon color="error" />,
    text: 'Двигатель внешнего клапана 2 работает слишком долго, а клапан не определяет, что он находится в нужном положении',
  },
  117: {
    icon: <WarningIcon color="error" />,
    text: 'Остановка двигателя внешнего клапана 2',
  },
  208: {
    icon: <WarningIcon color="error" />,
    text: 'Протечка воды. Датчик зафиксировал протечку. Активирована функция «ЗАЩИТА ОТ ПРОТЕЧЕК»',
  },
  209: {
    icon: <WarningIcon color="error" />,
    text: 'Ошибка регенерации',
  },
};

/**
 * Элемент блока ошибок.
 *
 * Получает все необходимые данные для отрисовки 
 * из внешнего компонента через Props,
 * не содержит внутреннее состояние.
 */
class WarningItem extends React.PureComponent {
  render() {
    const {
      text, name, id,
    } = this.props;
    const err = errors[id] || { icon: <ErrorIcon color="error" />, text: text || '{"error": "some unknown error"}' };
    return (
      <TableRow>
        <TableCell>{name || '?'}</TableCell>
        <TableCell>{err.icon}</TableCell>
        <TableCell>{err.text}</TableCell>
      </TableRow>
    );
  }
}

WarningItem.propTypes = {
  name: PropTypes.string.isRequired, // eslint-disable-line react/forbid-prop-types
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
};

WarningItem.defaultProps = {
  text: '',
};

export default withStyles(styles)(WarningItem);

// vim: ts=2 sw=2 et :
