import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';

import AjaxComponent from './AjaxComponent';

const styles = () => ({});

const options = {
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      dataLabels: { name: { show: false }, value: { offsetY: -15 } },
    },
  },
};

/**
 * График оставшегося ресурса клапана.
 *
 * Использует радиальный график ApexCharts.
 */
class DeviceCapGauge extends AjaxComponent {

  /**
   * Данные для запроса к RESTful API
   * @prop {string} c100 URL запроса по команде 100.
   * */
  urls = {
    c100: AjaxComponent.apiMessageGet(this.props.id, 100, 1),
  };

  /** Основная функция отрисовки компонента. */
  render() {
    const { c100 } = this.state;
    const { data } = Array.isArray(c100) && c100.length ? c100[0] : [];
    const series = data ? [
      data['cap-max'] ? Math.floor(data['cap-remain'] * 100 / data['cap-max']) : 0,
    ] : [];
    // console.log(series);
    return (
      <Chart
        options={options}
        type="radialBar"
        series={series}
        height="250"
      />
    );
  }
}

export default withStyles(styles)(DeviceCapGauge);

// vim: ts=2 sw=2 et :
