import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import LinearProgress from '@material-ui/core/LinearProgress';

import { API } from './AjaxComponent';

const styles = () => ({});

// const usage = {
//   unit: 'л',
//   f: v => v,
// };

// const flow = {
//   unit: 'л/мин',
//   f: v => v,
// };

const usage = {
  unit: 'м3',
  f: v => (v * 0.001).toFixed(3),
};

const flow = {
  unit: 'м3/ч',
  f: v => (v * 0.06).toFixed(2),
};

function devType(data) {
  const key = 'dissolve';
  if (data[key] === 'Softening') return 'Умягчение';
  if (data[key] === 'Filtering') return 'Фильтрация';
  return 'Нет данных';
}

/**
 * Блок вкладки "Информация" клапана.
 */
class DeviceInfo extends React.Component {
  /** Состояние React-компонента. */
  state = { rows: this.initRows() }

  /**
   * Функция инициализации компонента
   * (вызывается при появлении на странице).
   *
   * Запускает таймеры опросов данных и проводит первичные опросы API.
   */
  async componentDidMount() {
    const { id } = this.props;
    await API.queryDevice(id);
    this.timers = [
      { interval: 5000, code: 100 },
      { interval: 10000, code: 110 },
      { interval: 10000, code: 201 },
      { interval: 10000, code: 208 },
      { interval: 10000, code: 204 },
    ].map(item => ({
      ...item,
      fetcher: () => this.fetchData(item.code),
    })).map((item) => {
      item.fetcher();
      return setInterval(item.fetcher, item.interval);
    });
  }

  /**
   * Функция деинициализации компонента
   * (вызывается при удалении со страницы).
   *
   * Останавливает таймеры опросов данных.
   */
  componentWillUnmount() {
    if (Array.isArray(this.timers)) this.timers.map(item => clearInterval(item));
  }

  /*
   * Объект конфигурации извлечения и преобразования данных из ответа
   * сервера на API-запрос в значение, отображаемое в ячейке таблицы.
   * @returns {object} Конфигурационный массив, содержащий:
   * заголовог, код MQTT-сообщения с нужной информацией, анонимная функция,
   * преобразующая ответ сервера в значение, отображаемое в ячейке.
   */
  config() {
    return [
      // ['Артикул', null, () => this.props.name],
      ['MAC', null, () => this.props.id],
      ['Тип системы', 110, data => devType(data.data)],
      [`Полный ресурс, ${usage.unit}`, 100, data => usage.f(data.data['cap-max'])],
      ['Жесткость исходной воды, мг-экв/л', 201, data => data.data['Installer-Settings']['Water Hardness'] / 10.0],
      ['Текущее время', 208, (data) => {
        const [h, m] = ['Hour', 'Minute'].map(item => data.data['Time-Settings'][`Valve ${item}`]);
        const s = h * 3600 + m * 60 + data.lag;
        const [h2, m2] = [Math.floor(s / 3600) % 24, Math.floor(s / 60) % 60].map(v => v.toString().padStart(2, '0'));
        return `${h2}:${m2}`;
      }],
      [`Текущий расход воды, ${flow.unit}`, 100, data => flow.f(data.data['current-flow'])],
      [`Оставшийся ресурс, ${usage.unit}`, 100, data => usage.f(data.data['cap-remain'])],
      [`Объем очищенной воды после последней промывки, ${usage.unit}`, 204, data => usage.f(data.data['Diagnostic-Information']['Volume Since Regen'])],
      ['Число дней после последней промывки', 204, data => data.data['Diagnostic-Information']['Days Since Regen']],
      [`Максимальная скорость потока воды за последние 7 дней, ${flow.unit}`, 204, data => flow.f(Math.max(...data.data['Diagnostic-Information']['Max Flow'].filter(item => item !== '---')) || 0)],
    ];
  }

  /**
   * Инициализация строк таблицы. Для не загруженных (на текущий момент)
   * данных - погазываются индикаторы LinearProgress.
   */
  initRows() {
    return this.config().map(item => ({
      key: item[0],
      header: item[0],
      value: item[1] === null ? item[2]() : <LinearProgress />,
    }));
  }

  /**
   * Загрузка данных из API.
   * После загрузки данных заполняется таблица во всех строках,
   * за которые отвечает команда code, в соответствии с конфигурацией,
   * описанной в this.config.
   * @param {number} code Код команды MQTT.
   */
  async fetchData(code) {
    const { id } = this.props;
    const { rows } = this.state;
    const res = await API.req(API.message(id, code));
    this.config().forEach((item, index) => {
      if (code === item[1] && Array.isArray(res)) {
        rows[index].value = res.length ? item[2](res[0]) : 'нет данных';
      }
    });
    this.setState({ rows });
  }

  /** Основная функция отрисовки компонента. */
  render() {
    const { rows } = this.state;
    return (
      <Table>
        <TableBody>
          {
            rows.map(elem => (
              <TableRow key={elem.key}>
                <TableCell>{elem.header}</TableCell>
                <TableCell>{elem.value}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

DeviceInfo.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // rows: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(DeviceInfo);

// vim: ts=2 sw=2 et :
