const translations = {
  common: {
    Off: 'Выкл',
    On: 'Вкл',
    Auto: 'Авто',
    Monday: 'Понедельник',
    Tuesday: 'Вторник',
    Wednesday: 'Среда',
    Thursday: 'Четверг',
    Friday: 'Пятница',
    Saturday: 'Суббота',
    Sunday: 'Воскресенье',
    Softening: 'Умягчение',
    Filtering: 'Фильтрация',
    'No Hard Water Bypass': 'Без байпаса',
    'Alternator A': 'Клапан А',
    'Alternator B': 'Клапан Б',
    'System Controller': 'Системный контроллер',
    Immediate: 'Немедленная',
    Delayed: 'Отложенная',
    'Delayed + Immediate': 'Универсальная',
    'Delayed 2x': 'Отложенная x2',
    'Delayed 3x': 'Отложенная x3',
    'Delayed 4x': 'Отложенная x4',
    'Delayed 6x': 'Отложенная x6',
    Hold: 'Запрет промывки',
    MEQ: 'мг-экв/л',
    FH: 'fH',
    DH: 'dH',
    mins: 'мин',
    kg: 'кг',
  },
  201: {
    Time: 'По времени',
    'M^3': 'По объему',
    Both: 'По времени и объему',
  },
  202: {
  },
  203: {
    // Off: 'Обычное',
    Fill: 'Пропорциональное',
    // 'No Hard Water Bypass': 'Промывка чистой водой / сокращенная',
    'Separate Source': 'Промывка чистой водой / полная',
    'Leakage Alarm': 'Защита от протечек',
    Immediate: 'Немедленная промывка',
    Delayed: 'Отложенная промывка',
    'Regen Time': 'Промывка-Время',
    Volume: 'Сервис-Литры',
    'Regen Volume': 'Промывка-Литры',
    'Error Monitor': 'Ошибка',
    Regen: 'Промывка',
    Cycle: 'Стадия промывки',
  },
  204: {
  },
  205: {
  },
  206: {
    Fill: 'Заполнение бака',
    Backwash: 'Обратная промывка',
    Rinse: 'Прямая промывка',
    'Regenerant Draw Up': 'Регенерация снизу-вверх',
    'Regenerant Draw Down': 'Регенерация сверху-вниз',
    End: 'Конец',
  },
};

export default function tr(value, context = 'common') {
  if (translations[context] !== undefined && translations[context][value] !== undefined) {
    return translations[context][value];
  }
  if (translations.common[value] !== undefined) {
    return translations.common[value];
  }
  return value;
}
