import React from 'react';
import commonConf from './config-common';

const config = [
  {
    label: 'Блокировка кнопок',
    type: 'button',
    code: 210,
    payload: { Lock: 1 },
    id: 'lock',
  },
  {
    label: 'Снятие блокировки кнопок',
    type: 'button',
    code: 210,
    getter: data => data['Lock-Settings'].Lock === -1,
    payload: { Lock: 0 },
    id: 'unlock',
  },
  // {
  //   label: 'Перезагрузка',
  //   type: 'button',
  //   code: 113,
  //   get_payload: false,
  //   payload: { level: 0 },
  //   id: 'reboot',
  // },
  ...commonConf,
  {
    label: 'Контактная информация',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'On' },
    ],
    code: 201,
    getter: data => data['Installer-Settings']['Contact Screen'],
    setter: value => ({ 'Contact Screen': value }),
    id: 'contact-screen',
  },
  {
    label: 'Настройка телефона компании',
    type: 'text',
    code: 201,
    getter: data => data['Installer-Settings']['Contact Number'],
    setter: value => ({ 'Contact Number': value }),
    id: 'contact-number',
  },
  {
    label: 'Настройка названия компании',
    type: 'text',
    code: 201,
    getter: data => data['Installer-Settings']['Contact Name'],
    setter: value => ({ 'Contact Name': value }),
    id: 'contact-name',
  },
  {
    label: 'Сервис',
    type: 'select',
    options: [
      { value: 'Off' },
      { value: 'Time' },
      { value: 'M^3' },
      { value: 'Both' },
    ],
    code: 201,
    getter: data => data['Installer-Settings']['Service Alarm'],
    setter: value => ({ 'Service Alarm': value }),
    id: 'serive-alarm',
  },
  {
    label: 'По времени (год)',
    type: 'text',
    code: 201,
    getter: data => data['Installer-Settings']['Service Alarm Time'],
    setter: value => ({ 'Service Alarm Time': parseFloat(value) }),
    id: 'service-alarm-time',
  },
  {
    label: 'Сброс по времени',
    type: 'button',
    // xs: 2,
    // md: 1,
    code: 114,
    // getter: data => data['Lock-Settings'].Lock === -1,
    payload: { 'Service Alarm': 0 },
    id: 'reset-time',
  },
  {
    labelObj: <>По объёму (м<sup>3</sup>)</>,
    type: 'text',
    code: 201,
    getter: data => data['Installer-Settings']['Service Alarm Volume'],
    setter: value => ({ 'Service Alarm Volume': parseInt(value) }),
    id: 'service-alarm-volume',
  },
  {
    label: 'Сброс по объему',
    type: 'button',
    // xs: 2,
    // md: 1,
    code: 114,
    // getter: data => data['Lock-Settings'].Lock === -1,
    payload: { 'Service Alarm': 1 },
    id: 'reset-volume',
  },
  // {
  //   label: 'Остаток соли в баке (кг)',
  //   type: 'text',
  //   code: 115,
  //   get_payload: {},
  //   getter: data => data['Salt Settings']['Salt Remaining'],
  //   setter: value => ({ 'Salt Remaining': parseInt(value, 10) }),
  //   id: 'salt-remaining',
  // },
  // {
  //   label: 'Мин. остаток соли в баке (кг)',
  //   type: 'text',
  //   code: 115,
  //   get_payload: {},
  //   getter: data => data['Salt Settings']['Salt Alarm'],
  //   setter: value => ({ 'Salt Alarm': value === 'Off' ? 'Off' : parseInt(value, 10) }),
  //   id: 'salt-alarm',
  // },
  // {
  //   label: '',
  //   type: '',
  //   code: '',
  //   getter: data => data[''],
  //   setter: value => ({ value }),
  // },
];

export default config;
